import { Column } from '@/shared/interfaces/column.model'
import { formatDate } from '@/shared/utils/formatDate'
import { Component, reactive } from 'vue'
import { Training } from '../interfaces/training.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import { required } from '@/customValidators'

const isFirstFunction = (trainings: Training[], itemId: string) => {
  let foundIndex = 0
  trainings.find((training: Training, index: number) => {
    const found = training._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: "date",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
         {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper :auto-apply="true" style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @blur="v$.editedItem.date.$touch"  :enable-time-picker="false"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @blur="v$.newItem.date.$touch" :enable-time-picker="false" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as Training,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
            else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
            return true
          }
        },
        newItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
            else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "training.training-name",
    properties: "trainingName",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.trainingName }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.trainingName.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.trainingName.$error}" v-model="editedItem.trainingName" @blur="v$.editedItem.trainingName.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.trainingName.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.trainingName.$error}" v-model="newItem.trainingName" @blur="v$.newItem.trainingName.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Training,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          trainingName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { trainingName: vm.v$.editedItem.trainingName.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { trainingName: vm.v$.editedItem.trainingName.$invalid }
            else vm.isColumnsError.editedItem.trainingName = vm.v$.editedItem.trainingName.$invalid
            return true
          }
        },
        newItem: {
          trainingName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { trainingName: vm.v$.newItem.trainingName.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { trainingName: vm.v$.newItem.trainingName.$invalid }
            else vm.isColumnsError.newItem.trainingName = vm.v$.newItem.trainingName.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "training.who_has_been_trained",
    properties: "whoHasBeenTrained",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.whoHasBeenTrained }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.whoHasBeenTrained.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.whoHasBeenTrained.$error}" v-model="editedItem.whoHasBeenTrained" @blur="v$.editedItem.whoHasBeenTrained.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.whoHasBeenTrained.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.whoHasBeenTrained.$error}" v-model="newItem.whoHasBeenTrained" @blur="v$.newItem.whoHasBeenTrained.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Training,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          whoHasBeenTrained: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { whoHasBeenTrained: vm.v$.editedItem.whoHasBeenTrained.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { whoHasBeenTrained: vm.v$.editedItem.whoHasBeenTrained.$invalid }
            else vm.isColumnsError.editedItem.whoHasBeenTrained = vm.v$.editedItem.whoHasBeenTrained.$invalid
            return true
          }
        },
        newItem: {
          whoHasBeenTrained: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { whoHasBeenTrained: vm.v$.newItem.whoHasBeenTrained.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { whoHasBeenTrained: vm.v$.newItem.whoHasBeenTrained.$invalid }
            else vm.isColumnsError.newItem.whoHasBeenTrained = vm.v$.newItem.whoHasBeenTrained.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "training.who_trained",
    properties: "whoTrained",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.whoTrained }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.whoTrained.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.whoTrained.$error}" v-model="editedItem.whoTrained" @blur="v$.editedItem.whoTrained.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.whoTrained.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.whoTrained.$error}" v-model="newItem.whoTrained" @blur="v$.newItem.whoTrained.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Training,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          whoTrained: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { whoTrained: vm.v$.editedItem.whoTrained.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { whoTrained: vm.v$.editedItem.whoTrained.$invalid }
            else vm.isColumnsError.editedItem.whoTrained = vm.v$.editedItem.whoTrained.$invalid
            return true
          }
        },
        newItem: {
          whoTrained: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { whoTrained: vm.v$.newItem.whoTrained.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { whoTrained: vm.v$.newItem.whoTrained.$invalid }
            else vm.isColumnsError.newItem.whoTrained = vm.v$.newItem.whoTrained.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "name",
    properties: "whoAddRecord",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.whoAddRecord }}
        </div>
        <div v-else>
            <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.whoAddRecord.$errors">
              <input type="text" v-bind:class="{error: v$.editedItem.whoAddRecord.$error}" v-model="editedItem.whoAddRecord" @blur="v$.editedItem.whoAddRecord.$touch" />
            </FieldWrapper>
            <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.whoAddRecord.$errors">
              <input type="text" v-bind:class="{error: v$.newItem.whoAddRecord.$error}" v-model="newItem.whoAddRecord" @blur="v$.newItem.whoAddRecord.$touch" />
            </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Training,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          whoAddRecord: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { whoAddRecord: vm.v$.editedItem.whoAddRecord.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { whoAddRecord: vm.v$.editedItem.whoAddRecord.$invalid }
            else vm.isColumnsError.editedItem.whoAddRecord = vm.v$.editedItem.whoAddRecord.$invalid
            return true
          }
        },
        newItem: {
          whoAddRecord: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { whoAddRecord: vm.v$.newItem.whoAddRecord.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { whoAddRecord: vm.v$.newItem.whoAddRecord.$invalid }
            else vm.isColumnsError.newItem.whoAddRecord = vm.v$.newItem.whoAddRecord.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "trainingName", "whoHasBeenTrained", "whoTrained", "whoAddRecord"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as Training,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { 
            id: undefined, branchId: this.$store.state.activeBranch._id,
            date: new Date(),
            trainingName: '', 
            whoHasBeenTrained: '',
            whoTrained: '',
            whoAddRecord: '' });
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];
