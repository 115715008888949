import { Column } from '@/shared/interfaces/column.model'
import { formatDate } from '@/shared/utils/formatDate'
import { Component, reactive } from 'vue'
import { DayTemperatures } from '../interfaces/dayTemperatures.model'
import { saveTableData } from '../utils/saveTableData'
import { cancelTableData } from '../utils/cancelTableData'
import VueDatePicker from '@vuepic/vue-datepicker'
import { required, numeric } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import VueMultiselect from 'vue-multiselect'
import { Device } from '../interfaces/device.model'
import { helpers } from '@vuelidate/validators'

const isFirstFunction = (daysTemperatures: DayTemperatures[], itemId: string) => {
  let foundIndex = 0
  daysTemperatures.find((dayTemperatures: DayTemperatures, index: number) => {
    const found = dayTemperatures._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: ["date", "devicesTemperatures"],
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @update:model-value="blur('editedItem')" :enable-time-picker="false" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @update:model-value="blur('newItem')" :enable-time-picker="false" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as DayTemperatures,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations () {
        return {
          editedItem: {
            date: {
              required,
              tooEarly: helpers.withMessage(() => { return this.$t('day-temperatures.date_too_early') }, (value: Date) => {
                const resetedValue = new Date(value)
                resetedValue.setHours(0,0,0,0)
                const found = this.$store.state.editedItem.devicesTemperatures.find((devTemp: any) => {
                  const foundDevice = this.$store.state.devices.find((device: Device) => device.deviceId === devTemp.deviceId)
                  if (!foundDevice) return foundDevice
                  const resetedFoundDeviceDate = new Date(foundDevice.date)
                  resetedFoundDeviceDate.setHours(0,0,0,0)
                  return resetedValue.getTime() < resetedFoundDeviceDate.getTime()
                })
                return !found
              })
            },
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
              else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
              else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
              return true
            }
          },
          newItem: {
            date: {
              required
            },
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
              else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
              else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
              return true
            }
          }
        }
      },
      methods: {
        blur (type: 'editedItem' | 'newItem') {
          this.v$[type].date.$touch
          if (type === 'newItem') {
            this.$store.state.devices.forEach((device: Device) => {
              const foundDeviceTemp = this.devicesTemperatures.find((devTemp: any) => devTemp.deviceId === device.deviceId)
              if ((new Date(device.date)).getTime() > (new Date(this.newItem.date)).getTime() && foundDeviceTemp) this.devicesTemperatures.splice(this.devicesTemperatures.indexOf(foundDeviceTemp), 1)
              else if ((new Date(device.date)).getTime() <= (new Date(this.newItem.date)).getTime() && !foundDeviceTemp) this.devicesTemperatures.push({ device_id: device._id, deviceId: device.deviceId, morning: null, evening: null })
            })
          }
        }
      }
    } as Component
  },

  {
    name: "day-temperatures.devices",
    properties: "devicesTemperatures",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="flex gap-5">
          <div v-for="(deviceTemperature, index) in devicesTemperatures" :key="index">
            <div><b>{{ $t('day-temperatures.deviceIdShort') }}</b>: {{ deviceTemperature.deviceId }}</div>
            <div><b>{{ $t('morning') }}</b>: {{ deviceTemperature.morning }}°C</div>
            <div><b>{{ $t('evening') }}</b>: {{ deviceTemperature.evening }}°C</div>
          </div>
        </div>
        <div v-else-if="!isFirst && editedItem && editedItem._id" class="flex gap-5">
          <div v-for="(deviceTemperature, index) in editedItem.devicesTemperatures" :key="index" class="my-5">
            <FieldWrapper label="day-temperatures.deviceId" :errors="v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].deviceId.$errors : []">
              <VueMultiselect
                v-model="editedItem.devicesTemperatures[index].deviceId"
                :options="options"
                :deselectLabel="$t('deselect')"
                :selectedLabel="$t('selected')"
                :selectLabel="$t('select')"
                :placeholder="$t('selectOption')"
                @select="selectDevice($event, index)"
              >
                <template v-slot:noResult>
                  {{ $t('NoOptionsMatching') }}
                </template>
                <template v-slot:noOptions>
                  {{ $t('NoOptionsAvailable') }}
                </template>
              </VueMultiselect>
            </FieldWrapper>
            <FieldWrapper label="morning" :errors="v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].morning.$errors : []">
              <div style="display: inline-block; position: relative;">
                <input type="number" v-bind:class="{error: v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].morning.$error : null}" v-model="editedItem.devicesTemperatures[index].morning" @blur="v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].morning.$touch : () => {}" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>
            </FieldWrapper>
            <FieldWrapper label="evening" :errors="v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].evening.$errors : []">
              <div style="display: inline-block; position: relative;">            
                <input type="number" v-bind:class="{error: v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].$error : null}" v-model="editedItem.devicesTemperatures[index].evening" @blur="v$.editedItem.devicesTemperatures[index] ? v$.editedItem.devicesTemperatures[index].$touch : () => {}" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>
            </FieldWrapper>
            <button class="primary" @click="editedItem.devicesTemperatures.splice(index, 1)">
              {{ $t('remove') }}
            </button>
          </div>
          <div v-if="devicesForLog.length > 0" class="ps-5 border-s my-5">
            <FieldWrapper label="day-temperatures.newDeviceId" :errors="v$.newLogDevice.deviceId.$errors">
              <VueMultiselect
                v-model="newLogDevice.deviceId"
                :options="devicesForLog"
                :deselectLabel="$t('deselect')"
                :selectedLabel="$t('selected')"
                :selectLabel="$t('select')"
                :placeholder="$t('selectOption')"
                @select="selectNewDeviceForLog($event)"
              >
                <template v-slot:noResult>
                  {{ $t('NoOptionsMatching') }}
                </template>
                <template v-slot:noOptions>
                  {{ $t('NoOptionsAvailable') }}
                </template>
              </VueMultiselect>
            </FieldWrapper>
            <FieldWrapper label="morning" :errors="v$.newLogDevice.morning.$errors">
              <div style="display: inline-block; position: relative;">    
                <input type="number" v-bind:class="{error: v$.newLogDevice.morning.$error}" v-model="newLogDevice.morning" @blur="v$.newLogDevice.morning.$touch" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>            
            </FieldWrapper>
            <FieldWrapper label="evening" :errors="v$.newLogDevice.evening.$errors">
              <div style="display: inline-block; position: relative;">    
                <input type="number" v-bind:class="{error: v$.newLogDevice.$error}" v-model="newLogDevice.evening" @blur="v$.newLogDevice.$touch" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>
            </FieldWrapper>
            <button class="primary" @click="addNewDeviceToLog" :disabled="v$.newLogDevice.$invalid">
              {{ $t('day-temperatures.addToLog') }}
            </button>
          </div>
        </div>
        <div v-if="isFirst && newItem" class="flex gap-5">
          <div v-for="(deviceTemperature, index) in devicesTemperatures" :key="index" class="my-5">
            <div class="text-lg font-bold p-2">{{ $t('day-temperatures.deviceIdShort') }} {{ devicesTemperatures[index].deviceId }}</div>
            <FieldWrapper label="morning" :errors="v$.devicesTemperatures[index].morning.$errors">
              <div style="display: inline-block; position: relative;">    
                <input type="number" v-bind:class="{error: v$.devicesTemperatures[index].morning.$error}" v-model="devicesTemperatures[index].morning" @blur="v$.devicesTemperatures[index].morning.$touch" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>
            </FieldWrapper>
            <FieldWrapper label="evening" :errors="v$.devicesTemperatures[index].evening.$errors">
              <div style="display: inline-block; position: relative;">    
                <input type="number" v-bind:class="{error: v$.devicesTemperatures[index].evening.$error}" v-model="devicesTemperatures[index].evening" @blur="v$.devicesTemperatures[index].evening.$touch" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>            
            </FieldWrapper>
            <button class="primary" @click="devicesTemperatures.splice(index, 1)">
              {{ $t('remove') }}
            </button>
          </div>
          <div v-if="devicesForLog.length > 0" class="ps-5 border-s my-5">
            <FieldWrapper label="day-temperatures.newDeviceId" :errors="v$.newLogDevice.deviceId.$errors">
              <VueMultiselect
                v-model="newLogDevice.deviceId"
                :options="devicesForLog"
                :deselectLabel="$t('deselect')"
                :selectedLabel="$t('selected')"
                :selectLabel="$t('select')"
                :placeholder="$t('selectOption')"
                @select="selectNewDeviceForLog($event)"
              >
                <template v-slot:noResult>
                  {{ $t('NoOptionsMatching') }}
                </template>
                <template v-slot:noOptions>
                  {{ $t('NoOptionsAvailable') }}
                </template>
              </VueMultiselect>
            </FieldWrapper>
            <FieldWrapper label="morning" :errors="v$.newLogDevice.morning.$errors">
              <div style="display: inline-block; position: relative;">    
                <input type="number" v-bind:class="{error: v$.newLogDevice.morning.$error}" v-model="newLogDevice.morning" @blur="v$.newLogDevice.morning.$touch" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>            
            </FieldWrapper>
            <FieldWrapper label="evening" :errors="v$.newLogDevice.evening.$errors">
              <div style="display: inline-block; position: relative;">    
                <input type="number" v-bind:class="{error: v$.newLogDevice.$error}" v-model="newLogDevice.evening" @blur="v$.newLogDevice.$touch" style="padding-right: 25px;"/>
                <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
              </div>
            </FieldWrapper>
            <button class="primary" @click="addNewDeviceToLog" :disabled="v$.newLogDevice.$invalid">
              {{ $t('day-temperatures.addToLog') }}
            </button>
          </div>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper,
        VueMultiselect
      },
      props: {
        item: {} as DayTemperatures,
      },
      data: function () {
        return {
          newLogDevice: {
            device_id: null,
            deviceId: null,
            morning: null,
            evening: null
          }
        }
      },
      computed: {
        options () {
          return this.$store.state.devices.map((device: Device) => device.deviceId)
        },
        devicesForLog () {
          const item = this.isFirst && this.newItem ? this.newItem : this.editedItem
          const devicesTemperatures = this.isFirst && this.newItem ? this.devicesTemperatures : this.editedItem.devicesTemperatures
          return this.$store.state.devices.filter((device: Device) => {
            const resetedDeviceDate = new Date(device.date)
            resetedDeviceDate.setHours(0,0,0,0)
            const resetedLogDate = new Date(item.date)
            resetedLogDate.setHours(0,0,0,0)
            const foundInLog = devicesTemperatures.find((devTemp: any) => devTemp.deviceId === device.deviceId)
            return !foundInLog && resetedLogDate.getTime() >= resetedDeviceDate.getTime()
          }).map((device: Device) => device.deviceId)
        },
        editedDevicesTempValidations () {
          return this.editedItem.devicesTemperatures ? this.editedItem.devicesTemperatures.map(() => ({
            deviceId: { required },
            morning: { required, numeric },
            evening: { required, numeric },
          })) : []
        },
        newDevicesTempValidations () {
          return this.devicesTemperatures ? this.devicesTemperatures.map(() => ({
            morning: { required, numeric },
            evening: { required, numeric },
          })) : []
        },
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: function () {
        return {
          editedItem: {
            devicesTemperatures: this.editedDevicesTempValidations,
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { devicesTemperatures: vm.v$.editedItem.devicesTemperatures.$invalid } })
              else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { devicesTemperatures: vm.v$.editedItem.devicesTemperatures.$invalid }
              else vm.isColumnsError.editedItem.devicesTemperatures = vm.v$.editedItem.devicesTemperatures.$invalid
              return true
            }
          },
          newLogDevice: {
            deviceId: { required },
            morning: { required, numeric },
            evening: { required, numeric },
          },
          devicesTemperatures: this.newDevicesTempValidations,
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { devicesTemperatures: vm.v$.devicesTemperatures.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { devicesTemperatures: vm.v$.devicesTemperatures.$invalid }
            else vm.isColumnsError.newItem.devicesTemperatures = vm.v$.devicesTemperatures.$invalid
            return true
          }
        }
      },
      methods: {
        selectDevice (deviceId: string, index: number) {
          const device = this.$store.state.devices.find((searchedDevice: Device) => deviceId === searchedDevice.deviceId)
          this.editedItem.devicesTemperatures[index].deviceId = device.deviceId
          this.editedItem.devicesTemperatures[index].device_id = device._id
        },
        selectNewDeviceForLog (deviceId: string) {
          const device = this.$store.state.devices.find((searchedDevice: Device) => deviceId === searchedDevice.deviceId)
          this.newLogDevice.deviceId = device.deviceId
          this.newLogDevice.device_id = device._id
        },
        addNewDeviceToLog () {
          const devicesTemperatures = this.isFirst && this.newItem ? this.devicesTemperatures : this.editedItem.devicesTemperatures
          devicesTemperatures.push(JSON.parse(JSON.stringify(this.newLogDevice)))
          this.newLogDevice.deviceId = null
          this.newLogDevice.device_id = null
          this.newLogDevice.morning = null
          this.newLogDevice.evening = null
          this.v$.newLogDevice.$reset()
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "devicesTemperatures"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
          {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>        
      `,
      props: {
        item: {} as DayTemperatures,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', JSON.parse(JSON.stringify(this.item)))
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          const itemToSave = this.isFirst ? this.newItem : this.editedItem
          itemToSave.date = (new Date(itemToSave.date))
          itemToSave.date.setHours(0,0,0,0)
          saveTableData(
            this,
            isFirstFunction(this.$store.state.data, this.item._id),
            { id: undefined, branchId: this.$store.state.activeBranch._id, date: new Date(), devicesTemperatures: this.$store.state.devices.map((device: Device) => ({ device_id: device._id, deviceId: device.deviceId, morning: null, evening: null })) }
          )
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];