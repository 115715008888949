
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Filter',
  props: {
    rows: Number,
    current: Number,
    all: Number
  },
  emits: ['rowsChanged', 'pageChanged'],
  computed: {
    selected: {
      get: function (): number | undefined {
        return this.rows
      },
      set: function (rows: number): void {
        this.$emit('rowsChanged', rows)
      }
    }
  },
  methods: {
    changePage: function (direction: 1 | -1) {
      this.$emit('pageChanged', direction)
    }
  }
})
