import { Column } from '@/shared/interfaces/column.model'
import { Component, reactive } from 'vue'
import { Contact } from '../interfaces/contact.model'
import { saveTableData } from '../utils/saveTableData'
import { cancelTableData } from '../utils/cancelTableData'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { email, phone, required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'

const isFirstFunction = (contacts: Contact[], itemId: string) => {
  let foundIndex = 0
  contacts.find((contact: Contact, index: number) => {
    const found = contact._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
   {
    name: "contact.contact_name",
    properties: "contactName",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.contactName }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.contactName.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.contactName.$error}" v-model="editedItem.contactName" @blur="v$.editedItem.contactName.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.contactName.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.contactName.$error}" v-model="newItem.contactName" @blur="v$.newItem.contactName.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Contact,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          contactName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { contactName: vm.v$.editedItem.contactName.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { contactName: vm.v$.editedItem.contactName.$invalid }
            else vm.isColumnsError.editedItem.contactName = vm.v$.editedItem.contactName.$invalid
            return true
          }
        },
        newItem: {
          contactName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { contactName: vm.v$.newItem.contactName.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { contactName: vm.v$.newItem.contactName.$invalid }
            else vm.isColumnsError.newItem.contactName = vm.v$.newItem.contactName.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "phone_number",
    properties: "phoneNumber",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.phoneNumber }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.phoneNumber.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.phoneNumber.$error}" v-model="editedItem.phoneNumber" @blur="v$.editedItem.phoneNumber.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.phoneNumber.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.phoneNumber.$error}" v-model="newItem.phoneNumber" @blur="v$.newItem.phoneNumber.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Contact,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          phoneNumber: {
            required,
            phone
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { phoneNumber: vm.v$.editedItem.phoneNumber.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { phoneNumber: vm.v$.editedItem.phoneNumber.$invalid }
            else vm.isColumnsError.editedItem.phoneNumber = vm.v$.editedItem.phoneNumber.$invalid
            return true
          }
        },
        newItem: {
          phoneNumber: {
            required,
            phone
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { phoneNumber: vm.v$.newItem.phoneNumber.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { phoneNumber: vm.v$.newItem.phoneNumber.$invalid }
            else vm.isColumnsError.newItem.phoneNumber = vm.v$.newItem.phoneNumber.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "email",
    properties: "email",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.email }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.email.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.email.$error}" v-model="editedItem.email" @blur="v$.editedItem.email.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.email.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.email.$error}" v-model="newItem.email" @blur="v$.newItem.email.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Contact,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          email: {
            email
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { email: vm.v$.editedItem.email.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { email: vm.v$.editedItem.email.$invalid }
            else vm.isColumnsError.editedItem.email = vm.v$.editedItem.email.$invalid
            return true
          }
        },
        newItem: {
          email: {
            email
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { email: vm.v$.newItem.email.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { email: vm.v$.newItem.email.$invalid }
            else vm.isColumnsError.newItem.email = vm.v$.newItem.email.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "address",
    properties: "address",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.address }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.address.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.address.$error}" v-model="editedItem.address" @blur="v$.editedItem.address.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.address.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.address.$error}" v-model="newItem.address" @blur="v$.newItem.address.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Contact,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          address: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { address: vm.v$.editedItem.address.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { address: vm.v$.editedItem.address.$invalid }
            else vm.isColumnsError.editedItem.address = vm.v$.editedItem.address.$invalid
            return true
          }
        },
        newItem: {
          address: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { address: vm.v$.newItem.address.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { address: vm.v$.newItem.address.$invalid }
            else vm.isColumnsError.newItem.address = vm.v$.newItem.address.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "contact.for_which_advice",
    properties: "forWhichAdvice",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.forWhichAdvice }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.forWhichAdvice.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.forWhichAdvice.$error}" v-model="editedItem.forWhichAdvice" @blur="v$.editedItem.forWhichAdvice.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.forWhichAdvice.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.forWhichAdvice.$error}" v-model="newItem.forWhichAdvice" @blur="v$.newItem.forWhichAdvice.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Contact,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          forWhichAdvice: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { forWhichAdvice: vm.v$.editedItem.forWhichAdvice.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { forWhichAdvice: vm.v$.editedItem.forWhichAdvice.$invalid }
            else vm.isColumnsError.editedItem.forWhichAdvice = vm.v$.editedItem.forWhichAdvice.$invalid
            return true
          }
        },
        newItem: {
          forWhichAdvice: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { forWhichAdvice: vm.v$.newItem.forWhichAdvice.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { forWhichAdvice: vm.v$.newItem.forWhichAdvice.$invalid }
            else vm.isColumnsError.newItem.forWhichAdvice = vm.v$.newItem.forWhichAdvice.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["contactName", "phoneNumber", "email", "address", "forWhichAdvice"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as Contact,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id,  contactName: '', phoneNumber: '', email: '', address: '', forWhichAdvice: ''})
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,    
  }
] as Column[];