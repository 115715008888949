
import { defineComponent, reactive } from 'vue'
import PageTitle from '@/shared/components/PageTitle.vue'
import TableWrapper from '@/shared/components/TableWrapper.vue'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import cleaningColumns from '@/shared/columns/cleaningColumns'
import cleaningScheduleColumns from '@/shared/columns/cleaningScheduleColumns'
import proveItColumns from '@/shared/columns/proveItColumns'
import contactColumns from '@/shared/columns/contactColumns'
import deliveryColumns from '@/shared/columns/deliveryColumns'
import trainingColumns from '@/shared/columns/trainingColumns'
import probeColumns from '@/shared/columns/probeColumns'
import problemColumns from '@/shared/columns/problemColumns'
import supplierColumns from '@/shared/columns/supplierColumns'
import openingColumns from '@/shared/columns/openingColumns'
import closingColumns from '@/shared/columns/closingColumns'
import monthlyReviewColumns from '@/shared/columns/monthlyReviewColumns'
import daysTemperaturesColumns from '@/shared/columns/daysTemperaturesColumns'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import VueMultiselect from 'vue-multiselect'
import dishesAllergenColumns from '@/shared/columns/dishesAllergenColumns'
import foodHandlingColumns from '@/shared/columns/foodHandlingColumns'
import { Device } from '@/shared/interfaces/device.model'
import { helpers } from '@vuelidate/validators'

export default defineComponent({
  components: {
    VueMultiselect,
    PageTitle,
    TableWrapper,
    VueDatePicker,
    FieldWrapper
  },
  setup: () => {
    const store = useStore()
    store.commit('setVuelidateExternalResults', {})
    return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
  },
  validations: function () {
    return {
      addition: {
        deviceDate: { required },
        deviceId: {
          required,
          deviceIdExists: helpers.withMessage(() => {
            return this.$t('day-temperatures.id_not_found')
          }, (deviceId: string) => {
            const found = this.$store.state.devices.some((device: Device) => device.deviceId === deviceId)
            return !found
          })
        }
      },
      removing: {
        deviceToRemove: { required },
        removeDate: { required }
      }
    }
  },
  data: function () {
    return {
      addition: {
        deviceDate: new Date(),
        deviceId: ''
      },
      isLoading: false,
      showModal: false,
      key: 0,
      removing: {
        deviceToRemove: null as Device | null,
        removeDate: new Date()
      }
    }
  },
  computed: {
    action () {
      if (this.$route.params.action === 'day-temperatures') {
        return this.$route.params.action as string + '.' + this.$route.params.type as string
      }
      return this.$route.params.action as string
    },
    columns () {
      let columnsArray = null
      switch (this.$route.params.action) {
        case 'day-temperatures':
          columnsArray = daysTemperaturesColumns
          break
        case 'cleaning':
          columnsArray = cleaningColumns
          break
        case 'cleaning-schedule':
          columnsArray = cleaningScheduleColumns
          break
        case 'prove-it':
          columnsArray = proveItColumns
          break
        case 'contact':
          columnsArray = contactColumns
          break
        case 'training':
          columnsArray = trainingColumns
          break
        case 'delivery':
          columnsArray = deliveryColumns
          break
        case 'probe':
          columnsArray = probeColumns
          break
        case 'problem':
          columnsArray = problemColumns
          break
        case 'supplier':
          columnsArray = supplierColumns
          break
        case 'opening':
          columnsArray = openingColumns
          break
        case 'closing':
          columnsArray = closingColumns
          break
        case 'monthly-review':
          columnsArray = monthlyReviewColumns
          break
        case 'dishes-allergen':
          columnsArray = dishesAllergenColumns
          break
        case 'food-handling':
          columnsArray = foodHandlingColumns
          break
      }
      return columnsArray || []
    }
  },
  watch: {
    $route (to, from) {
      this.$store.commit('setData', [])
      if (from.path !== to.path) this.key++
    }
  },
  methods: {
    async add () {
      const isFormCorrect = await this.v$.addition.$validate()
      if (!isFormCorrect) return
      this.isLoading = true
      const dateReseted = new Date(this.addition.deviceDate as Date)
      dateReseted.setHours(0, 0, 0, 0)
      this.$store.dispatch('addDevice', { date: dateReseted, deviceId: this.addition.deviceId, branchId: this.$store.state.activeBranch._id })
        .then(() => {
          this.isLoading = false
          this.addition.deviceDate = new Date()
          this.addition.deviceId = ''
          this.key++
          this.v$.addition.$reset()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    async remove () {
      const isFormCorrect = await this.v$.removing.$validate()
      if (!isFormCorrect) return
      this.isLoading = true
      const dateReseted = new Date(this.removing.removeDate as Date)
      dateReseted.setHours(0, 0, 0, 0)
      this.$store.dispatch('deleteDevice', { date: dateReseted, _id: this.removing.deviceToRemove ? this.removing.deviceToRemove._id : null })
        .then(() => {
          this.isLoading = false
          this.removing.removeDate = new Date()
          this.removing.deviceToRemove = null
          this.key++
          this.v$.removing.$reset()
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  }
})
