export const cancelTableData = (that: any) => {
  Object.keys(that.$store.state.editedItem).forEach((key: string) => {
    if (that[key]) that.item[key] = that[key]
  })
  that.$store.commit('setEditedItem', {})
  if (that.item && !!that.item.checklist) {
    that.item.checklist = JSON.parse(JSON.stringify(that.$store.state.originalChecklist))
    that.$store.commit('setOriginalChecklist', [])
  }
}
