import { Column } from '@/shared/interfaces/column.model'
import { formatDate } from '@/shared/utils/formatDate'
import { Component, reactive } from 'vue'
import { MonthlyReview } from '../interfaces/monthlyReview.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import Modal from '@/shared/components/Modal.vue'
import Toggle from '@/shared/components/Toggle.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import { helpers } from '@vuelidate/validators'

const isFirstFunction = (monthlyReviews: MonthlyReview[], itemId: string) => {
  let foundIndex = 0
  monthlyReviews.find((monthlyReview: MonthlyReview, index: number) => {
    const found = monthlyReview._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: "date",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @blur="v$.editedItem.date.$touch" :enable-time-picker="false"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @blur="v$.newItem.date.$touch" :enable-time-picker="false"/>
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as MonthlyReview,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
            else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
            return true
          }
        },
        newItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
            else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "monthly-review.any_problems",
    properties: "check0",
    trueValue: true,
    noSort: false,
    editionState: 'editedItem'
  },  
  {
    name: "details",
    properties: ["check0", "details"],
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.details }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.details.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.details.$error}" v-model="editedItem.details" @blur="v$.editedItem.details.$touch" :disabled="!editedItem.check0 && !editedItem.details" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.details.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.details.$error}" v-model="newItem.details" @blur="v$.newItem.details.$touch" :disabled="!newItem.check0" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as MonthlyReview,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations () {
        return {
          editedItem: {
            details: {
              required: helpers.withMessage(() => { return this.$t('validation.required') }, (value: String) => (value && value.length > 0) || !this.editedItem.check0)
            },
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { details: vm.v$.editedItem.details.$invalid } })
              else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { details: vm.v$.editedItem.details.$invalid }
              else vm.isColumnsError.editedItem.details = vm.v$.editedItem.details.$invalid
              return true
            }
          },
          newItem: {
            details: {
              required: helpers.withMessage(() => { return this.$t('validation.required') }, (value: String) => (value && value.length > 0) || !this.newItem.check0)
            },
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { details: vm.v$.newItem.details.$invalid } })
              else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { details: vm.v$.newItem.details.$invalid }
              else vm.isColumnsError.newItem.details = vm.v$.newItem.details.$invalid
              return true
            }
          }
        }
      }
    } as Component
  },  
  {
    name: "monthly-review.action_taken",
    properties: ["check0", "whatDidDo"],
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.whatDidDo }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.whatDidDo.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.whatDidDo.$error}" v-model="editedItem.whatDidDo" @blur="v$.editedItem.whatDidDo.$touch" :disabled="!editedItem.check0 && !editedItem.whatDidDo"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.whatDidDo.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.whatDidDo.$error}" v-model="newItem.whatDidDo" @blur="v$.newItem.whatDidDo.$touch" :disabled="!newItem.check0"/>
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as MonthlyReview,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations () {
        return {
          editedItem: {
            whatDidDo: {
              required: helpers.withMessage(() => { return this.$t('validation.required') }, (value: String) => (value && value.length > 0) || !this.editedItem.check0)
            },
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { whatDidDo: vm.v$.editedItem.whatDidDo.$invalid } })
              else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { whatDidDo: vm.v$.editedItem.whatDidDo.$invalid }
              else vm.isColumnsError.editedItem.whatDidDo = vm.v$.editedItem.whatDidDo.$invalid
              return true
            }
          },
          newItem: {
            whatDidDo: {
              required: helpers.withMessage(() => { return this.$t('validation.required') }, (value: String) => (value && value.length > 0) || !this.editedItem.check0)
            },
            changed: (value: string, vm: any) => {
              if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { whatDidDo: vm.v$.newItem.whatDidDo.$invalid } })
              else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { whatDidDo: vm.v$.newItem.whatDidDo.$invalid }
              else vm.isColumnsError.newItem.whatDidDo = vm.v$.newItem.whatDidDo.$invalid
              return true
            }
          }
        }
      }
    } as Component
  },  
  {
    name: "monthly-review.checklist",
    properties: ["check1", "check2", "check3", "check4", "check5", "check6", "check7", "check8", "check9", "check10", "check11", "check12"],
    customComponent: {
      template: `
        <button class="primary whitespace-nowrap" @click="showModal = true">{{ $t('monthly-review.open') }}</button>
        <Modal v-if="showModal" title="checklist" @closed="cancel()" :hideCancel="true">
          <div>
            <label>{{ $t('monthly-review.check1') }}</label>
            <Toggle class="mb-5" :modelValue="item.check1" @update:modelValue="$event => item.check1 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check2') }}</label>
            <Toggle class="mb-5" :modelValue="item.check2" @update:modelValue="$event => item.check2 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check3') }}</label>
            <Toggle class="mb-5" :modelValue="item.check3" @update:modelValue="$event => item.check3 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check4') }}</label>
            <Toggle class="mb-5" :modelValue="item.check4" @update:modelValue="$event => item.check4 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check5') }}</label>
            <Toggle class="mb-5" :modelValue="item.check5" @update:modelValue="$event => item.check5 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check6') }}</label>
            <Toggle class="mb-5" :modelValue="item.check6" @update:modelValue="$event => item.check6 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check7') }}</label>
            <Toggle class="mb-5" :modelValue="item.check7" @update:modelValue="$event => item.check7 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check8') }}</label>
            <Toggle class="mb-5" :modelValue="item.check8" @update:modelValue="$event => item.check8 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check9') }}</label>
            <Toggle class="mb-5" :modelValue="item.check9" @update:modelValue="$event => item.check9 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check10') }}</label>
            <Toggle class="mb-5" :modelValue="item.check10" @update:modelValue="$event => item.check10 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check11') }}</label>
            <Toggle class="mb-5" :modelValue="item.check11" @update:modelValue="$event => item.check11 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('monthly-review.check12') }}</label>
            <Toggle class="mb-5" :modelValue="item.check12" @update:modelValue="$event => item.check12 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
          </div>
          <template #footer>
            <button class="primary" @click="confirm()">
              {{ $t('ok') }}
            </button>
          </template>
        </Modal>
      `,
      components: { Modal,Toggle },
      props: {
        item: {} as MonthlyReview,
      },
      data: function () {
        return {
          showModal: false
        }
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        }
      },
      methods: {
        close () {
          this.showModal = false
        },
        confirm () {
          if (this.isFirst && this.newItem) {
            this.check0 = this.item.check0
            this.check1 = this.item.check1
            this.check2 = this.item.check2
            this.check3 = this.item.check3
            this.check4 = this.item.check4
            this.check5 = this.item.check5
            this.check6 = this.item.check6
            this.check7 = this.item.check7
            this.check8 = this.item.check8
            this.check9 = this.item.check9
            this.check10 = this.item.check10
            this.check11 = this.item.check11
            this.check12 = this.item.check12
            }
          this.close()
        },
        cancel () {
          this.item.check1 = this.check1
          this.item.check2 = this.check2
          this.item.check3 = this.check3
          this.item.check4 = this.check4
          this.item.check5 = this.check5
          this.item.check6 = this.check6
          this.item.check7 = this.check7
          this.item.check8 = this.check8
          this.item.check9 = this.check9
          this.item.check10 = this.check10
          this.item.check11 = this.check11
          this.item.check12 = this.check12
          this.close()
        }
      }
    } as Component
  },
  {
    name: "additional",
    properties: "additional",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.additional }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.additional.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.additional.$error}" v-model="editedItem.additional" @blur="v$.editedItem.additional.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.additional.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.additional.$error}" v-model="newItem.additional" @blur="v$.newItem.additional.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as MonthlyReview,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          additional: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { additional: vm.v$.editedItem.additional.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { additional: vm.v$.editedItem.additional.$invalid }
            else vm.isColumnsError.editedItem.additional = vm.v$.editedItem.additional.$invalid
            return true
          }
        },
        newItem: {
          additional: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { additional: vm.v$.newItem.additional.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { additional: vm.v$.newItem.additional.$invalid }
            else vm.isColumnsError.newItem.additional = vm.v$.newItem.additional.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "name",
    properties: "name",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.name }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.name.$error}" v-model="editedItem.name" @blur="v$.editedItem.name.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.name.$error}" v-model="newItem.name" @blur="v$.newItem.name.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as MonthlyReview,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { name: vm.v$.editedItem.name.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { name: vm.v$.editedItem.name.$invalid }
            else vm.isColumnsError.editedItem.name = vm.v$.editedItem.name.$invalid
            return true
          }
        },
        newItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { name: vm.v$.newItem.name.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { name: vm.v$.newItem.name.$invalid }
            else vm.isColumnsError.newItem.name = vm.v$.newItem.name.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "check0", "details", "whatDidDo","check1", "check2", "check3", "check4", "check5", "check6", "check7", "check8", "check9", "check10", "check11", "check12", "additional", "name"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as MonthlyReview,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id, date: new Date(), check0: false, details: '', whatDidDo: '', check1: false, check2: false, check3: false, check4: false, check5: false, check6: false, check7: false, check8: false, check9: false, check10: false, check11: false, check12: false, additional: '', name: '' })
        },
        cancel () {
          this.$store.dispatch('getData', {})
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];