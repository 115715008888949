import { Column } from '@/shared/interfaces/column.model'
import { Component, reactive } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { CleaningSchedule } from '../interfaces/cleaningSchedule.model'
import { saveTableData } from '../utils/saveTableData'
import { cancelTableData } from '../utils/cancelTableData'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import Multiselect from 'vue-multiselect'
import { required } from '@/customValidators'

const isFirstFunction = (cleaningSchedules: CleaningSchedule[], itemId: string) => {
  let foundIndex = 0
  cleaningSchedules.find((cleaningSchedule: CleaningSchedule, index: number) => {
    const found = cleaningSchedule._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
   {
    name: "item",
    properties: "thing",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.thing }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.thing.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.thing.$error}" v-model="editedItem.thing" @blur="v$.editedItem.thing.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.thing.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.thing.$error}" v-model="newItem.thing" @blur="v$.newItem.thing.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as CleaningSchedule,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          thing: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { thing: vm.v$.editedItem.thing.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { thing: vm.v$.editedItem.thing.$invalid }
            else vm.isColumnsError.editedItem.thing = vm.v$.editedItem.thing.$invalid
            return true
          }
        },
        newItem: {
          thing: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { thing: vm.v$.newItem.thing.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { thing: vm.v$.newItem.thing.$invalid }
            else vm.isColumnsError.newItem.thing = vm.v$.newItem.thing.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "cleaning-schedule.frequency",
    properties: "frequency",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ translateFrequency(item.frequency) }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst" :errors="v$.completeValue.$errors">
            <multiselect
              v-model="completeValue"
              :options="options"
              :custom-label="getLabel"
            >
            </multiselect>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-else :errors="v$.completeValue.$errors">
            <multiselect
              v-model="completeValue"
              :options="options"
              :custom-label="getLabel"
              :placeholder="$t('selectOption')"
              :deselectLabel="$t('deselect')"
              :selectedLabel="$t('selected')"
              :selectLabel="$t('select')"
            >
              <template v-slot:noResult>
                {{ $t('NoOptionsMatching') }}
              </template>
              <template v-slot:noOptions>
                {{ $t('NoOptionsAvailable') }}
              </template>
            </multiselect>
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: { Multiselect, FieldWrapper },
      props: {
        item: {} as CleaningSchedule,
      },
      data: function () {
        return {
          options: [
            { value: 'daily', label: 'cleaning-schedule.daily' },
            { value: 'weekly', label: 'cleaning-schedule.weekly' },
            { value: 'monthly', label: 'cleaning-schedule.monthly' }
          ]
        }
      },
      computed: {
        isFirst() {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        completeValue: {
          get () {
            const item = this.$store.state[this.isFirst ? 'newItem' : 'editedItem']
            return item && item.frequency ? this.options.find((option: { value: string, label: string }) => option.value === item.frequency) : undefined
          },
          set (v) {
            const item = this.$store.state[this.isFirst ? 'newItem' : 'editedItem']
            item.frequency = v ? v.value : undefined  
          }
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        completeValue: {
          required
        },
        changed: (value: string, vm: any) => {
          const itemType = vm.isFirst ? 'newItem' : 'editedItem'
          if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { [itemType]: { frequency: vm.v$.completeValue.$invalid } })
          else if (!vm.isColumnsError[itemType]) vm.isColumnsError[itemType] = { frequency: vm.v$.completeValue.$invalid }
          else vm.isColumnsError[itemType].frequency = vm.v$.completeValue.$invalid
          return true
        }
      },
      methods: {
        getLabel ({ label }: { label: string }) {
          return this.$t(label)
        },
        setValue (option: { value: string, label: string }) {
          this.$store.state[this.isFirst ? 'newItem' : 'editedItem'].frequency = option.value
        },
        translateFrequency(frequency: any) {
          const frequencyTranslationKey = `cleaning-schedule.${frequency}`;
          return this.$t(frequencyTranslationKey);
        }
      }
    } as Component,
  },
  {
    name: "cleaning-schedule.precautions",
    properties: "precautions",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.precautions }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.precautions.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.precautions.$error}" v-model="editedItem.precautions" @blur="v$.editedItem.precautions.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.precautions.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.precautions.$error}" v-model="newItem.precautions" @blur="v$.newItem.precautions.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as CleaningSchedule,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          precautions: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { precautions: vm.v$.editedItem.precautions.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { precautions: vm.v$.editedItem.precautions.$invalid }
            else vm.isColumnsError.editedItem.precautions = vm.v$.editedItem.precautions.$invalid
            return true
          }
        },
        newItem: {
          precautions: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { precautions: vm.v$.newItem.precautions.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { precautions: vm.v$.newItem.precautions.$invalid }
            else vm.isColumnsError.newItem.precautions = vm.v$.newItem.precautions.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "cleaning-schedule.method_cleaning",
    properties: "methodCleaning",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.methodCleaning }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.methodCleaning.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.methodCleaning.$error}" v-model="editedItem.methodCleaning" @blur="v$.editedItem.methodCleaning.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.methodCleaning.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.methodCleaning.$error}" v-model="newItem.methodCleaning" @blur="v$.newItem.methodCleaning.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as CleaningSchedule,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          methodCleaning: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { methodCleaning: vm.v$.editedItem.methodCleaning.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { methodCleaning: vm.v$.editedItem.methodCleaning.$invalid }
            else vm.isColumnsError.editedItem.methodCleaning = vm.v$.editedItem.methodCleaning.$invalid
            return true
          }
        },
        newItem: {
          methodCleaning: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { methodCleaning: vm.v$.newItem.methodCleaning.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { methodCleaning: vm.v$.newItem.methodCleaning.$invalid }
            else vm.isColumnsError.newItem.methodCleaning = vm.v$.newItem.methodCleaning.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["thing", "frequency", "precautions", "methodCleaning"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as CleaningSchedule,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id,  thing: '', frequency: '', precautions: '', methodCleaning: ''})
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,    
  }
] as Column[];