import { Column } from '@/shared/interfaces/column.model'
import { formatDate } from '@/shared/utils/formatDate'
import { Component, reactive } from 'vue'
import { Cleaning } from '../interfaces/cleaning.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import Modal from '@/shared/components/Modal.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { CleaningSchedule } from '../interfaces/cleaningSchedule.model'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'

const isFirstFunction = (cleanings: Cleaning[], itemId: string) => {
  let foundIndex = 0
  cleanings.find((cleaning: Cleaning, index: number) => {
    const found = cleaning._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: "date",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
        {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @blur="v$.editedItem.date.$touch"  :enable-time-picker="false"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @blur="v$.newItem.date.$touch" :enable-time-picker="false" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as Cleaning,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
            else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
            return true
          }
        },
        newItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
            else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "cleaning.daily",
    properties: "checklist",
    customComponent: {
      template: `
      <button class="primary whitespace-nowrap" @click="showModal = true">{{ $t('closing.open') }}</button>
      <Modal v-if="showModal" :hideCancel="!editMode && (!editedItem || !editedItem._id) && !isFirst" title="Checklist" @closed="cancel()">
        <ul>
          <li v-for="(checkbox, index) in dailyItems" :key="checkbox._id" class="flex items-center">
            <input
              :disabled="!editMode && (!editedItem || !editedItem._id) && !isFirst"
              class="me-2"
              type="checkbox"
              :id="'check' + index"
              :name="'check' + index"
              :checked="item.checklist.find(check => check._id === checkbox._id)"
              @input="updateCheckbox($event, checkbox)"
              :value="checkbox"
            />
            <label class="mb-0" :for="'check' + index">{{ checkbox.thing }}</label>
          </li>
        </ul>
        <template #footer>
          <button v-if="editMode || (editedItem && editedItem._id) || isFirst" class="primary" @click="saveChecklist()">
            {{ $t('save') }}
          </button>
          <button v-else class="primary" @click="edit()">
            {{ $t('edit') }}
          </button>
        </template>
      </Modal>
    `,
      components: { Modal },
      props: {
        item: {} as Cleaning,
      },
      data: function () {
        return {
          showModal: false,
          editMode: false,
          originalChecklist: [],
        };
      },
      computed: {
        dailyItems() {
          const available = this.$store.state.checklist.filter((item: { frequency: string }) => item.frequency === "daily");
          const rest = this.item.checklist.filter((checked: CleaningSchedule) => !available.find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'daily')
          let original = []
          if (this.editMode) original = this.originalChecklist.filter((checked: CleaningSchedule) => ![...available, ...rest].find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'daily')
          else if (this.editedItem && this.editedItem._id) {
            original = this.$store.state.originalChecklist.filter((checked: CleaningSchedule) => ![...available, ...rest].find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'daily')
          }
          return [...available, ...rest, ...original]
        },
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },        
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        updateCheckbox ($event: any, value: CleaningSchedule) {
          const found = this.item.checklist.find((checkbox: CleaningSchedule) => value && checkbox._id === value._id)
          if (found) this.item.checklist = this.item.checklist.filter((checkbox: CleaningSchedule) => checkbox._id !== value._id)
          else this.item.checklist.push(value)
        },
        close () {
          this.editMode = false
          this.showModal = false
        },
        saveChecklist() {
          if (!this.isFirst) this.$store.dispatch('saveChecklist', this.item);
          this.$store.commit('setOriginalChecklist', [])
          this.close();
        },
        edit() {
          if (!this.isFirst) this.originalChecklist = JSON.parse(JSON.stringify(this.item.checklist));        
          this.editMode = true;
        },
        cancel() {
          if (this.editMode) this.item.checklist = JSON.parse(JSON.stringify(this.originalChecklist));
          else if (this.editedItem && this.editedItem._id) this.item.checklist = JSON.parse(JSON.stringify(this.$store.state.originalChecklist))
          this.showModal = false;
          if (!this.isFirst) this.editMode = false;
        }
      },
    } as Component
  },
  {
    name: "cleaning.weekly",    
    customComponent: {
      template: `
      <button class="primary whitespace-nowrap" @click="openModal()">{{ $t('closing.open') }}</button>
      <Modal v-if="showModal" :hideCancel="!editMode && (!editedItem || !editedItem._id) && !isFirst" title="Checklist" @closed="cancel()">
        <ul>
          <li v-for="(checkbox, index) in weeklyItems" :key="checkbox._id" class="flex items-center">
            <input
              :disabled="!editMode && (!editedItem || !editedItem._id) && !isFirst"
              class="me-2"
              type="checkbox"
              :id="'check' + index"
              :name="'check' + index"
              :checked="item.checklist.find(check => check._id === checkbox._id)"
              @input="updateCheckbox($event, checkbox)"
              :value="checkbox"
            />
              <label class="mb-0" :for="'check' + index">{{ checkbox.thing }}</label>
              <div v-if="isCompletedWeek(checkbox)" class="ms-2">
                {{ $t('cleaning.beenCompletedWeek') }}
              </div>
              <div class="ms-2" v-else>
                {{ $t('cleaning.notCompletedWeek') }}
              </div>
          </li>
        </ul>
        <template #footer>
          <button v-if="editMode || (editedItem && editedItem._id) || isFirst" class="primary" @click="saveChecklist()">
            {{ $t('save') }}
          </button>
          <button v-else class="primary" @click="edit()">
            {{ $t('edit') }}
          </button>
        </template>
      </Modal>
    `,
      components: { Modal },
      props: {
        item: {} as Cleaning,
      },
      data: function () {
        return {
          showModal: false,
          editMode: false,
          originalChecklist: [],
          cleaningSchedulesDoneChecks: []
        };
      },
      computed: {
        weeklyItems() {
          const available = this.$store.state.checklist.filter((item: { frequency: string }) => item.frequency === "weekly");
          const rest = this.item.checklist.filter((checked: CleaningSchedule) => !available.find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'weekly')
          let original = []
          if (this.editMode) original = this.originalChecklist.filter((checked: CleaningSchedule) => ![...available, ...rest].find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'weekly')
          else if (this.editedItem && this.editedItem._id) {
            original = this.$store.state.originalChecklist.filter((checked: CleaningSchedule) => ![...available, ...rest].find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'weekly')
          }
          return [...available, ...rest, ...original]
        },
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        async openModal() {
          this.showModal = true
          await this.$store.dispatch('checkCleaningSchedules', { date: this.item.date, cleaningSchedules: this.weeklyItems.map((item: CleaningSchedule) => item._id) })
            .then((result: any) => {
              this.cleaningSchedulesDoneChecks = result
            })
        },
        isCompletedWeek(checkbox: any) {
          const foundCompletion = this.cleaningSchedulesDoneChecks.find((cleaningCompletion: any) => cleaningCompletion._id === checkbox._id)
          return foundCompletion && foundCompletion.week
        },
        updateCheckbox ($event: any, value: CleaningSchedule) {
          const found = this.item.checklist.find((checkbox: CleaningSchedule) => value && checkbox._id === value._id)
          if (found) this.item.checklist = this.item.checklist.filter((checkbox: CleaningSchedule) => checkbox._id !== value._id)
          else this.item.checklist.push(value)
        },
        close () {
          this.editMode = false
          this.showModal = false
        },
        saveChecklist() {
          if (!this.isFirst) this.$store.dispatch('saveChecklist', this.item);
          this.$store.commit('setOriginalChecklist', [])
          this.close();
        },
        edit() {
          if (!this.isFirst) this.originalChecklist = JSON.parse(JSON.stringify(this.item.checklist));        
          this.editMode = true;
        },
        cancel() {
          if (this.editMode) this.item.checklist = JSON.parse(JSON.stringify(this.originalChecklist));
          else if (this.editedItem && this.editedItem._id) this.item.checklist = JSON.parse(JSON.stringify(this.$store.state.originalChecklist))
          this.showModal = false;
          if (!this.isFirst) this.editMode = false;
        }
      },      
    } as Component
  },
  {
    name: "cleaning.monthly",
    customComponent: {
      template: `
      <button class="primary whitespace-nowrap" @click="openModal()">{{ $t('closing.open') }}</button>
      <Modal v-if="showModal" :hideCancel="!editMode && (!editedItem || !editedItem._id) && !isFirst" title="Checklist" @closed="cancel()">
        <ul>
          <li v-for="(checkbox, index) in monthlyItems" :key="checkbox._id" class="flex items-center">
            <input 
              :disabled="!editMode && (!editedItem || !editedItem._id) && !isFirst"
              class="me-2"
              type="checkbox"
              :id="'check' + index"
              :name="'check' + index"
              :checked="item.checklist.find(check => check._id === checkbox._id)"
              @input="updateCheckbox($event, checkbox)"
              :value="checkbox"
            />
            <label class="mb-0" :for="'check' + index">{{ checkbox.thing }}</label>
            <div v-if="isCompletedMonth(checkbox)" class="ms-2">
              {{ $t('cleaning.beenCompletedMonth') }}
            </div>
            <div class="ms-2" v-else>
              {{ $t('cleaning.notCompletedMonth') }} 
            </div>
          </li>
        </ul>
        <template #footer>
          <button v-if="editMode || (editedItem && editedItem._id) || isFirst" class="primary" @click="saveChecklist()">
            {{ $t('save') }}
          </button>
          <button v-else class="primary" @click="edit()">
            {{ $t('edit') }}
          </button>
        </template>
      </Modal>
    `,
      components: { Modal },
      props: {
        item: {} as Cleaning,
      },
      data: function () {
        return {
          showModal: false,
          editMode: false,
          originalChecklist: [],
          cleaningSchedulesDoneChecks: []
        };
      },
      computed: {
        monthlyItems() {
          const available = this.$store.state.checklist.filter((item: { frequency: string }) => item.frequency === "monthly");
          const rest = this.item.checklist.filter((checked: CleaningSchedule) => !available.find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'monthly')
          let original = []
          if (this.editMode) original = this.originalChecklist.filter((checked: CleaningSchedule) => ![...available, ...rest].find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'monthly')
          else if (this.editedItem && this.editedItem._id) {
            original = this.$store.state.originalChecklist.filter((checked: CleaningSchedule) => ![...available, ...rest].find((availableCheck: CleaningSchedule) => checked._id === availableCheck._id) && checked.frequency === 'monthly')
          }
          return [...available, ...rest, ...original]
        },
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        async openModal() {
          this.showModal = true
          await this.$store.dispatch('checkCleaningSchedules', { date: this.item.date, cleaningSchedules: this.monthlyItems.map((item: CleaningSchedule) => item._id) })
            .then((result: any) => {
              this.cleaningSchedulesDoneChecks = result
            })
        },
        isCompletedMonth(checkbox: any) {
          const foundCompletion = this.cleaningSchedulesDoneChecks.find((cleaningCompletion: any) => cleaningCompletion._id === checkbox._id)
          return foundCompletion && foundCompletion.month
        },
        updateCheckbox ($event: any, value: CleaningSchedule) {
          const found = this.item.checklist.find((checkbox: CleaningSchedule) => value && checkbox._id === value._id)
          if (found) this.item.checklist = this.item.checklist.filter((checkbox: CleaningSchedule) => checkbox._id !== value._id)
          else this.item.checklist.push(value)
        },
        close () {
          this.editMode = false
          this.showModal = false
        },
        saveChecklist() {
          if (!this.isFirst) this.$store.dispatch('saveChecklist', this.item);
          this.$store.commit('setOriginalChecklist', [])
          this.close();
        },
        edit() {
          if (!this.isFirst) this.originalChecklist = JSON.parse(JSON.stringify(this.item.checklist));        
          this.editMode = true;
        },
        cancel() {
          if (this.editMode) this.item.checklist = JSON.parse(JSON.stringify(this.originalChecklist));
          else if (this.editedItem && this.editedItem._id) this.item.checklist = JSON.parse(JSON.stringify(this.$store.state.originalChecklist))
          this.showModal = false;
          if (!this.isFirst) this.editMode = false;
        },
        closeModal() {
          this.modalOpen = false
          this.editMode = false
        }
      },
    } as Component
  },
  {
    name: "additional",
    properties: "additional",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.additional }}
        </div>
        <div v-else>
          <input v-if="!isFirst && editedItem && editedItem._id" type="text" v-model="editedItem.additional" />
          <input v-if="isFirst && newItem" type="text" v-model="newItem.additional" />
        </div>
      `,
      props: {
        item: {} as Cleaning,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        }
      }
    } as Component
  },
  {
    name: "name",
    properties: "name",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.name }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.name.$error}" v-model="editedItem.name" @blur="v$.editedItem.name.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.name.$error}" v-model="newItem.name" @blur="v$.newItem.name.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Cleaning,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { name: vm.v$.editedItem.name.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { name: vm.v$.editedItem.name.$invalid }
            else vm.isColumnsError.editedItem.name = vm.v$.editedItem.name.$invalid
            return true
          }
        },
        newItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { name: vm.v$.newItem.name.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { name: vm.v$.newItem.name.$invalid }
            else vm.isColumnsError.newItem.name = vm.v$.newItem.name.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "additional", "name"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as Cleaning,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
          this.$store.commit('setOriginalChecklist', JSON.parse(JSON.stringify(this.item.checklist)))
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id, date: new Date(), checklist: [], name: '' })
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];