
import { defineComponent } from 'vue'
import Filter from '@/shared/components/Filter.vue'
import PlusIcon from '@/shared/components/base-icon/icons/PlusIcon.vue'
import SearchIcon from '@/shared/components/base-icon/icons/SearchIcon.vue'

export default defineComponent({
  name: 'TablePreheader',
  props: {
    placeholder: String,
    addButtonText: String,
    isFilter: Boolean
  },
  components: {
    Filter,
    PlusIcon,
    SearchIcon
  },
  data: function () {
    return {
      searchText: '' as string
    }
  },
  emits: ['search', 'addButtonClicked'],
  methods: {
    search: function (value: string) {
      this.$emit('search', value)
    },
    clear: function () {
      this.searchText = ''
      this.search('')
    }
  }
})
