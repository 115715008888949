import { Column } from '@/shared/interfaces/column.model'
import { Component, reactive } from 'vue'
import { Supplier } from '../interfaces/supplier.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { email, phone, required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'

const isFirstFunction = (suppliers: Supplier[], itemId: string) => {
  let foundIndex = 0
  suppliers.find((supplier: Supplier, index: number) => {
    const found = supplier._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "supplier.business_name",
    properties: "businessName",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.businessName }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.businessName.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.businessName.$error}" v-model="editedItem.businessName" @blur="v$.editedItem.businessName.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.businessName.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.businessName.$error}" v-model="newItem.businessName" @blur="v$.newItem.businessName.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          businessName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { businessName: vm.v$.editedItem.businessName.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { businessName: vm.v$.editedItem.businessName.$invalid }
            else vm.isColumnsError.editedItem.businessName = vm.v$.editedItem.businessName.$invalid
            return true
          }
        },
        newItem: {
          businessName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { businessName: vm.v$.newItem.businessName.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { businessName: vm.v$.newItem.businessName.$invalid }
            else vm.isColumnsError.newItem.businessName = vm.v$.newItem.businessName.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "supplier.contact_name",
    properties: "contactName",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.contactName }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.contactName.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.contactName.$error}" v-model="editedItem.contactName" @blur="v$.editedItem.contactName.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.contactName.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.contactName.$error}" v-model="newItem.contactName" @blur="v$.newItem.contactName.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          contactName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { contactName: vm.v$.editedItem.contactName.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { contactName: vm.v$.editedItem.contactName.$invalid }
            else vm.isColumnsError.editedItem.contactName = vm.v$.editedItem.contactName.$invalid
            return true
          }
        },
        newItem: {
          contactName: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { contactName: vm.v$.newItem.contactName.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { contactName: vm.v$.newItem.contactName.$invalid }
            else vm.isColumnsError.newItem.contactName = vm.v$.newItem.contactName.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "phone_number",
    properties: "phone",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.phone }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.phone.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.phone.$error}" v-model="editedItem.phone" @blur="v$.editedItem.phone.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.phone.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.phone.$error}" v-model="newItem.phone" @blur="v$.newItem.phone.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          phone: {
            required,
            phone
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { phone: vm.v$.editedItem.phone.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { phone: vm.v$.editedItem.phone.$invalid }
            else vm.isColumnsError.editedItem.phone = vm.v$.editedItem.phone.$invalid
            return true
          }
        },
        newItem: {
          phone: {
            required,
            phone
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { phone: vm.v$.newItem.phone.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { phone: vm.v$.newItem.phone.$invalid }
            else vm.isColumnsError.newItem.phone = vm.v$.newItem.phone.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "email",
    properties: "email",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.email }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.email.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.email.$error}" v-model="editedItem.email" @blur="v$.editedItem.email.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.email.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.email.$error}" v-model="newItem.email" @blur="v$.newItem.email.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          email: {
            email
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { email: vm.v$.editedItem.email.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { email: vm.v$.editedItem.email.$invalid }
            else vm.isColumnsError.editedItem.email = vm.v$.editedItem.email.$invalid
            return true
          }
        },
        newItem: {
          email: {
            email
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { email: vm.v$.newItem.email.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { email: vm.v$.newItem.email.$invalid }
            else vm.isColumnsError.newItem.email = vm.v$.newItem.email.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "address",
    properties: "address",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.address }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.address.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.address.$error}" v-model="editedItem.address" @blur="v$.editedItem.address.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.address.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.address.$error}" v-model="newItem.address" @blur="v$.newItem.address.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          address: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { address: vm.v$.editedItem.address.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { address: vm.v$.editedItem.address.$invalid }
            else vm.isColumnsError.editedItem.address = vm.v$.editedItem.address.$invalid
            return true
          }
        },
        newItem: {
          address: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { address: vm.v$.newItem.address.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { address: vm.v$.newItem.address.$invalid }
            else vm.isColumnsError.newItem.address = vm.v$.newItem.address.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "supplier.delivery_days",
    properties: "deliveryDays",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.deliveryDays }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.deliveryDays.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.deliveryDays.$error}" v-model="editedItem.deliveryDays" @blur="v$.editedItem.deliveryDays.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.deliveryDays.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.deliveryDays.$error}" v-model="newItem.deliveryDays" @blur="v$.newItem.deliveryDays.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          deliveryDays: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { deliveryDays: vm.v$.editedItem.deliveryDays.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { deliveryDays: vm.v$.editedItem.deliveryDays.$invalid }
            else vm.isColumnsError.editedItem.deliveryDays = vm.v$.editedItem.deliveryDays.$invalid
            return true
          }
        },
        newItem: {
          deliveryDays: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { deliveryDays: vm.v$.newItem.deliveryDays.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { deliveryDays: vm.v$.newItem.deliveryDays.$invalid }
            else vm.isColumnsError.newItem.deliveryDays = vm.v$.newItem.deliveryDays.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "supplier.order_lead_time",
    properties: "leadTimePlacingOrder",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.leadTimePlacingOrder }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.leadTimePlacingOrder.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.leadTimePlacingOrder.$error}" v-model="editedItem.leadTimePlacingOrder" @blur="v$.editedItem.leadTimePlacingOrder.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.leadTimePlacingOrder.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.leadTimePlacingOrder.$error}" v-model="newItem.leadTimePlacingOrder" @blur="v$.newItem.leadTimePlacingOrder.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          leadTimePlacingOrder: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { leadTimePlacingOrder: vm.v$.editedItem.leadTimePlacingOrder.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { leadTimePlacingOrder: vm.v$.editedItem.leadTimePlacingOrder.$invalid }
            else vm.isColumnsError.editedItem.leadTimePlacingOrder = vm.v$.editedItem.leadTimePlacingOrder.$invalid
            return true
          }
        },
        newItem: {
          leadTimePlacingOrder: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { leadTimePlacingOrder: vm.v$.newItem.leadTimePlacingOrder.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { leadTimePlacingOrder: vm.v$.newItem.leadTimePlacingOrder.$invalid }
            else vm.isColumnsError.newItem.leadTimePlacingOrder = vm.v$.newItem.leadTimePlacingOrder.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "supplier.which_goods",
    properties: "whichGoods",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.whichGoods }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.whichGoods.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.whichGoods.$error}" v-model="editedItem.whichGoods" @blur="v$.editedItem.whichGoods.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.whichGoods.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.whichGoods.$error}" v-model="newItem.whichGoods" @blur="v$.newItem.whichGoods.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          whichGoods: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { whichGoods: vm.v$.editedItem.whichGoods.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { whichGoods: vm.v$.editedItem.whichGoods.$invalid }
            else vm.isColumnsError.editedItem.whichGoods = vm.v$.editedItem.whichGoods.$invalid
            return true
          }
        },
        newItem: {
          whichGoods: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { whichGoods: vm.v$.newItem.whichGoods.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { whichGoods: vm.v$.newItem.whichGoods.$invalid }
            else vm.isColumnsError.newItem.whichGoods = vm.v$.newItem.whichGoods.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["businessName", "contactName", "phone", "email", "address", "deliveryDays", "leadTimePlacingOrder", "whichGoods"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as Supplier,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { 
            id: undefined, 
            branchId: this.$store.state.activeBranch._id, 
            businessName: '',
            contactName: '', 
            phone: '', 
            email: '', 
            address: '', 
            deliveryDays: '',    
            leadTimePlacingOrder: '', 
            whichGoods: '' })
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];