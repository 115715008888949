import { Column } from '@/shared/interfaces/column.model'
import { Component, reactive } from 'vue'
import { DishesAllergen } from '../interfaces/dishesAllergen.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import Multiselect from 'vue-multiselect'

const isFirstFunction = (dishesAllergens: DishesAllergen[], itemId: string) => {
  let foundIndex = 0
  dishesAllergens.find((dishesAllergen: DishesAllergen, index: number) => {
    const found = dishesAllergen._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [  
  {
    name: "dishes-allergen.dish",
    properties: "dish",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.dish }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.dish.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.dish.$error}" v-model="editedItem.dish" @blur="v$.editedItem.dish.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.dish.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.dish.$error}" v-model="newItem.dish" @blur="v$.newItem.dish.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as DishesAllergen,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          dish: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { dish: vm.v$.editedItem.dish.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { dish: vm.v$.editedItem.dish.$invalid }
            else vm.isColumnsError.editedItem.dish = vm.v$.editedItem.dish.$invalid
            return true
          }
        },
        newItem: {
          dish: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { dish: vm.v$.newItem.dish.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { dish: vm.v$.newItem.dish.$invalid }
            else vm.isColumnsError.newItem.dish = vm.v$.newItem.dish.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "dishes-allergen.allergens",
    properties: "allergens",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.allergens ? item.allergens.map(allergen => allergenLabel(allergen)).join(', ') : '' }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id">
            <multiselect
              :multiple="true"
              :closeOnSelect="false"
              :options="possibleAllergens"
              v-model="editedItem.allergens"
              :placeholder="$t('selectOption')"
              :deselectLabel="$t('deselect')"
              :selectedLabel="$t('selected')"
              :selectLabel="$t('select')"
              :custom-label="allergenLabel"

            >
            </multiselect>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem">
            <multiselect
              :multiple="true"
              :closeOnSelect="false"
              :options="possibleAllergens"
              v-model="newItem.allergens"
              :placeholder="$t('selectOption')"
              :deselectLabel="$t('deselect')"
              :selectedLabel="$t('selected')"
              :selectLabel="$t('select')"
              :custom-label="allergenLabel"
            >
            </multiselect>
          </FieldWrapper>      
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
      },
      components: {
        FieldWrapper,
        Multiselect
      },
      props: {
        item: {} as DishesAllergen,
      },
      data: function () {
        return {
          possibleAllergens: [
            'containCelery',
            'containCereals',
            'containCrustaceans',
            'containEggs',
            'containFish',
            'containLupin',
            'containMilk',
            'containMollusc',
            'containMustard',
            'containNuts',
            'containPeanuts',
            'containSesame',
            'containSoya',
            'containSulphurDioxide'
          ]
        }
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        allergenLabel(possibleAllergens: any) {
          const translationKey = possibleAllergens ? `dishes-allergen.${possibleAllergens}` : ''
          return this.$t(translationKey)
        }
      }
    } as Component
  },  
  {
    name: "actions",
    properties: ["dish", "allergens"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as DishesAllergen,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        translateAllergens(allergens: any[]) {
          return allergens.map(allergen => this.$t(`dishes-allergen.${allergen}`));
        },
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id, dish: '', allergens: [] })
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];