import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-lg text-start mb-5 font-bold" }
const _hoisted_3 = { class: "flex flex-col lg:flex-row items-start lg:gap-2" }
const _hoisted_4 = { class: "flex flex-col lg:flex-row items-start lg:gap-2" }
const _hoisted_5 = ["placeholder", "disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "flex flex-col lg:flex-row items-start lg:gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_FieldWrapper = _resolveComponent("FieldWrapper")!
  const _component_VueMultiselect = _resolveComponent("VueMultiselect")!
  const _component_TableWrapper = _resolveComponent("TableWrapper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PageTitle, {
      title: _ctx.action + '.title',
      subtitle: _ctx.action + '.subtitle'
    }, null, 8, ["title", "subtitle"]),
    (_ctx.$route.params.action === 'day-temperatures')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('day-temperatures.addSection')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FieldWrapper, {
                label: "day-temperatures.date",
                name: "deviceDate",
                errors: _ctx.v$.addition.deviceDate.$errors
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VueDatePicker, {
                    "auto-apply": true,
                    clearable: false,
                    modelValue: _ctx.addition.deviceDate,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addition.deviceDate) = $event)),
                    "enable-time-picker": false
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["errors"]),
              _createVNode(_component_FieldWrapper, {
                label: "day-temperatures.deviceId",
                name: "deviceId",
                errors: _ctx.v$.addition.deviceId.$errors
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("input", {
                    name: "deviceId",
                    id: "deviceId",
                    type: "text",
                    class: _normalizeClass([{error: _ctx.v$.addition.deviceId.$error}, "focus:shadow"]),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addition.deviceId) = $event)),
                    placeholder: _ctx.$t('day-temperatures.deviceId_placeholder'),
                    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.v$.addition.deviceId.$touch && _ctx.v$.addition.deviceId.$touch(...args))),
                    disabled: _ctx.isLoading
                  }, null, 42, _hoisted_5), [
                    [_vModelText, _ctx.addition.deviceId]
                  ])
                ]),
                _: 1
              }, 8, ["errors"])
            ]),
            _createElementVNode("button", {
              class: "primary mb-2 lg:mt-8",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
              disabled: _ctx.v$.addition.$invalid
            }, _toDisplayString(_ctx.$t('add')), 9, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FieldWrapper, {
              label: "day-temperatures.selectADeviceToRemove",
              name: "removeDevice",
              errors: _ctx.v$.removing.deviceToRemove.$errors
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VueMultiselect, {
                  modelValue: _ctx.removing.deviceToRemove,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.removing.deviceToRemove) = $event)),
                  options: _ctx.$store.state.devices,
                  label: "deviceId",
                  "track-by": "_id",
                  placeholder: _ctx.$t('select_one'),
                  deselectLabel: _ctx.$t('deselect'),
                  selectedLabel: _ctx.$t('selected'),
                  selectLabel: _ctx.$t('select'),
                  NoResult: _ctx.$t('select'),
                  noOptionsText: _ctx.$t('select'),
                  noOptions: _ctx.$t('select')
                }, {
                  noResult: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsMatching')), 1)
                  ]),
                  noOptions: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsAvailable')), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "placeholder", "deselectLabel", "selectedLabel", "selectLabel", "NoResult", "noOptionsText", "noOptions"])
              ]),
              _: 1
            }, 8, ["errors"]),
            _createVNode(_component_FieldWrapper, {
              label: "day-temperatures.date",
              name: "removeDate",
              errors: _ctx.v$.removing.removeDate.$errors
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VueDatePicker, {
                  "auto-apply": true,
                  clearable: false,
                  modelValue: _ctx.removing.removeDate,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.removing.removeDate) = $event)),
                  "enable-time-picker": false
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["errors"]),
            _createElementVNode("button", {
              class: "primary lg:mt-8",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)))
            }, _toDisplayString(_ctx.$t('remove')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$store.state.activeBranch && _ctx.$store.state.activeBranch._id)
      ? (_openBlock(), _createBlock(_component_TableWrapper, {
          "state-name": "data",
          "state-to-set-name": "data",
          columns: _ctx.columns,
          key: _ctx.key
        }, null, 8, ["columns"]))
      : _createCommentVNode("", true)
  ]))
}