import { Column } from '@/shared/interfaces/column.model'
import { Component, reactive } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { FoodHandling } from '../interfaces/foodHandling.model'
import { saveTableData } from '../utils/saveTableData'
import { cancelTableData } from '../utils/cancelTableData'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import Multiselect from 'vue-multiselect'
import { numeric, required } from '@/customValidators'
import { formatDate } from '../utils/formatDate'
import VueDatePicker from '@vuepic/vue-datepicker'

const isFirstFunction = (foodHandlings: FoodHandling[], itemId: string) => {
  let foundIndex = 0
  foodHandlings.find((foodHandling: FoodHandling, index: number) => {
    const found = foodHandling._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: "date",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @blur="v$.editedItem.date.$touch" :enable-time-picker="false"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @blur="v$.newItem.date.$touch" :enable-time-picker="false"/>
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
            else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
            return true
          }
        },
        newItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
            else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
            return true
          }
        }
      }
    } as Component
  },
   {
    name: "food-handling.food",
    properties: "food",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.food }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.food.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.food.$error}" v-model="editedItem.food" @blur="v$.editedItem.food.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.food.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.food.$error}" v-model="newItem.food" @blur="v$.newItem.food.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          food: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { food: vm.v$.editedItem.food.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { food: vm.v$.editedItem.food.$invalid }
            else vm.isColumnsError.editedItem.food = vm.v$.editedItem.food.$invalid
            return true
          }
        },
        newItem: {
          food: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { food: vm.v$.newItem.food.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { food: vm.v$.newItem.food.$invalid }
            else vm.isColumnsError.newItem.food = vm.v$.newItem.food.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "food-handling.choiceCookingCoolingReheating",
    properties: "choiceCookingCoolingReheating",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ $t('food-handling.' + item.choiceCookingCoolingReheating) }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst" :errors="v$.completeValue.$errors">
            <multiselect
              v-model="completeValue"
              :options="options"
              :custom-label="getLabel"
            >
            </multiselect>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-else :errors="v$.completeValue.$errors">
            <multiselect
              v-model="completeValue"
              :options="options"
              :custom-label="getLabel"
              :placeholder="$t('selectOption')"
              :deselectLabel="$t('deselect')"
              :selectedLabel="$t('selected')"
              :selectLabel="$t('select')"
            >
              <template v-slot:noResult>
                {{ $t('NoOptionsMatching') }}
              </template>
              <template v-slot:noOptions>
                {{ $t('NoOptionsAvailable') }}
              </template>
            </multiselect>
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: { Multiselect, FieldWrapper },
      props: {
        item: {} as FoodHandling,
      },
      data: function () {
        return {
          options: [
            { value: 'cooking', label: 'food-handling.cooking' },
            { value: 'cooling', label: 'food-handling.cooling' },
            { value: 'reheating', label: 'food-handling.reheating' }
          ]
        }
      },
      computed: {
        isFirst() {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        completeValue: {
          get () {
            const item = this.$store.state[this.isFirst ? 'newItem' : 'editedItem']
            return item && item.choiceCookingCoolingReheating ? this.options.find((option: { value: string, label: string }) => option.value === item.choiceCookingCoolingReheating) : undefined
          },
          set (v) {
            const item = this.$store.state[this.isFirst ? 'newItem' : 'editedItem']
            item.choiceCookingCoolingReheating = v ? v.value : undefined  
          }
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        completeValue: {
          required
        },
        changed: (value: string, vm: any) => {
          const itemType = vm.isFirst ? 'newItem' : 'editedItem'
          if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { [itemType]: { choiceCookingCoolingReheating: vm.v$.completeValue.$invalid } })
          else if (!vm.isColumnsError[itemType]) vm.isColumnsError[itemType] = { choiceCookingCoolingReheating: vm.v$.completeValue.$invalid }
          else vm.isColumnsError[itemType].choiceCookingCoolingReheating = vm.v$.completeValue.$invalid
          return true
        }
      },
      methods: {
        getLabel ({ label }: { label: string }) {
          return this.$t(label)
        },
        setValue (option: { value: string, label: string }) {
          this.$store.state[this.isFirst ? 'newItem' : 'editedItem'].choiceCookingCoolingReheating = option.value
        }
      }
    } as Component,
  },

  
  {
    name: "food-handling.timeStart",
    properties: "timeStart",
    noSort: true,
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
         {{ timeFormatted }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.timeStart.$errors">
            <VueDatePicker :clearable="false" v-bind:class="{error: v$.editedItem.timeStart.$error}" v-model="editedItem.timeStart" @blur="v$.editedItem.timeStart.$touch" time-picker :format="formatTime" :key="key" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.timeStart.$errors">
            <VueDatePicker :clearable="false" v-bind:class="{error: v$.newItem.timeStart.$error}" v-model="newItem.timeStart" @blur="v$.newItem.timeStart.$touch" time-picker :format="formatTime" :key="key" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      data: function () {
        return {
          key: 0 as Number
        }
      },
      computed: {
        timeFormatted: function () {
          const date = new Date()
          date.setHours(this.item.timeStart.hours, this.item.timeStart.minutes, this.item.timeStart.seconds, 0)
          return this.item ? formatDate(date, 'hh:mm A') : null
        },
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          timeStart: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { timeStart: vm.v$.editedItem.timeStart.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { timeStart: vm.v$.editedItem.timeStart.$invalid }
            else vm.isColumnsError.editedItem.timeStart = vm.v$.editedItem.timeStart.$invalid
            return true
          }
        },
        newItem: {
          timeStart: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { timeStart: vm.v$.newItem.timeStart.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { timeStart: vm.v$.newItem.timeStart.$invalid }
            else vm.isColumnsError.newItem.timeStart = vm.v$.newItem.timeStart.$invalid
            return true
          }
        }
      },
      methods: {
        formatTime (date: Date) {
          return formatDate(date, 'hh:mm A')
        }
      }
    } as Component
  },  
  {
    name: "food-handling.timeFinish",
    properties: "timeFinish",
    noSort: true,
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ timeFormatted }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.timeFinish.$errors">
            <VueDatePicker :clearable="false" v-bind:class="{error: v$.editedItem.timeFinish.$error}" v-model="editedItem.timeStart" @blur="v$.editedItem.timeFinish.$touch" time-picker :format="formatTime" :key="key" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.timeFinish.$errors">
            <VueDatePicker :clearable="false" v-bind:class="{error: v$.newItem.timeFinish.$error}" v-model="newItem.timeFinish" @blur="v$.newItem.timeFinish.$touch" time-picker :format="formatTime" :key="key" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      data: function () {
        return {
          key: 0 as Number
        }
      },
      computed: {
        timeFormatted: function () {
          const date = new Date()
          date.setHours(this.item.timeFinish.hours, this.item.timeFinish.minutes, this.item.timeFinish.seconds, 0)
          return this.item ? formatDate(date, 'hh:mm A') : null
        },
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          timeFinish: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { timeFinish: vm.v$.editedItem.timeFinish.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { timeFinish: vm.v$.editedItem.timeFinish.$invalid }
            else vm.isColumnsError.editedItem.timeFinish = vm.v$.editedItem.timeFinish.$invalid
            return true
          }
        },
        newItem: {
          timeFinish: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { timeFinish: vm.v$.newItem.timeFinish.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { timeFinish: vm.v$.newItem.timeFinish.$invalid }
            else vm.isColumnsError.newItem.timeFinish = vm.v$.newItem.timeFinish.$invalid
            return true
          }
        }
      },
      methods: {
        formatTime (date: Date) {
          return formatDate(date, 'hh:mm A')
        }
      }
    } as Component
  },  
  {
    name: "day-temperatures.translation",
    properties: "temperature",
    customComponent: {
      template: `
      <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
        {{ item.temperature }}°C
      </div>
      <div v-else>
        <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.temperature.$errors">
          <div style="display: inline-block; position: relative;">
            <input type="number" v-bind:class="{error: v$.editedItem.temperature.$error}" v-model="editedItem.temperature" @blur="v$.editedItem.temperature.$touch" style="padding-right: 25px;"/>
            <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
          </div>
        </FieldWrapper>
        <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.temperature.$errors">
          <div style="display: inline-block; position: relative;">
            <input type="number" v-bind:class="{error: v$.newItem.temperature.$error}" v-model="newItem.temperature" @blur="v$.newItem.temperature.$touch" style="padding-right: 25px;"/>
            <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
          </div>
        </FieldWrapper>
      </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          temperature: {
            required,
            numeric
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { temperature: vm.v$.editedItem.temperature.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { temperature: vm.v$.editedItem.temperature.$invalid }
            else vm.isColumnsError.editedItem.temperature = vm.v$.editedItem.temperature.$invalid
            return true
          }
        },
        newItem: {
          temperature: {
            required,
            numeric
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { temperature: vm.v$.newItem.temperature.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { temperature: vm.v$.newItem.temperature.$invalid }
            else vm.isColumnsError.newItem.temperature = vm.v$.newItem.temperature.$invalid
            return true
          }
        }
      }
    } as Component
  },  
  {
    name: "name",
    properties: "name",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.name }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.name.$error}" v-model="editedItem.name" @blur="v$.editedItem.name.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.name.$error}" v-model="newItem.name" @blur="v$.newItem.name.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { name: vm.v$.editedItem.name.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { name: vm.v$.editedItem.name.$invalid }
            else vm.isColumnsError.editedItem.name = vm.v$.editedItem.name.$invalid
            return true
          }
        },
        newItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { name: vm.v$.newItem.name.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { name: vm.v$.newItem.name.$invalid }
            else vm.isColumnsError.newItem.name = vm.v$.newItem.name.$invalid
            return true
          }
        }
      }
    } as Component
  },  
  {
    name: "food-handling.comment",
    properties: "comment",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.comment }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.comment.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.comment.$error}" v-model="editedItem.comment" @blur="v$.editedItem.comment.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.comment.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.comment.$error}" v-model="newItem.comment" @blur="v$.newItem.comment.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as FoodHandling,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          comment: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { comment: vm.v$.editedItem.comment.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { comment: vm.v$.editedItem.comment.$invalid }
            else vm.isColumnsError.editedItem.comment = vm.v$.editedItem.comment.$invalid
            return true
          }
        },
        newItem: {
          comment: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { comment: vm.v$.newItem.comment.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { comment: vm.v$.newItem.comment.$invalid }
            else vm.isColumnsError.newItem.comment = vm.v$.newItem.comment.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "food", "choiceCookingCoolingReheating", "timeStart", "timeFinish", "temperature", "name", "comment"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as FoodHandling,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id,  date: new Date(), food: '', choiceCookingCoolingReheating: '', timeStart: { hours: (new Date()).getHours(), minutes: (new Date()).getMinutes(), seconds: 0 }, timeFinish: { hours: (new Date()).getHours(), minutes: (new Date()).getMinutes(), seconds: 0 }, temperature: '', name: '', comment: '' })
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,    
  }
] as Column[];