
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Toggle',
  props: {
    modelValue: Boolean,
    disabled: Boolean
  },
  emits: ['update:modelValue'],
  methods: {
    toggled () {
      if (!this.disabled) this.$emit('update:modelValue', !this.modelValue)
    }
  }
})
