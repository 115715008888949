import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea146432"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emptyState w-full pt-10 pb-8 px-5 bg-secondary rounded" }
const _hoisted_2 = {
  key: 0,
  class: "text-xl text-center text-textColor font-bold mb-2.5"
}
const _hoisted_3 = {
  key: 1,
  class: "emptyState-subtitle text-center mx-auto max-w-full text-sm text-textHeaderColor mb-6"
}
const _hoisted_4 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1))
      : _createCommentVNode("", true),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.subtitle)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}