import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tableCell"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")!

  return (!!_ctx.item && !!_ctx.itemColumn)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!!_ctx.itemColumn.trueValue)
          ? (_openBlock(), _createBlock(_component_Toggle, {
              key: 0,
              modelValue: _ctx.toggledInColumn,
              disabled: _ctx.isDisabled,
              "onUpdate:modelValue": _ctx.toggled
            }, null, 8, ["modelValue", "disabled", "onUpdate:modelValue"]))
          : (_ctx.itemColumn.isLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.item[_ctx.itemColumn.properties],
                target: "blank",
                class: "text-primary text-sm font-bold"
              }, _toDisplayString(_ctx.item[_ctx.itemColumn.properties]), 9, _hoisted_2))
            : (_ctx.itemColumn.customComponent)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentInstance), {
                  key: 2,
                  item: _ctx.item
                }, null, 8, ["item"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.item[_ctx.itemColumn.properties]), 1))
      ]))
    : _createCommentVNode("", true)
}