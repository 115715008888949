
import { defineComponent } from 'vue'
import Pagination from '@/shared/components/Pagination.vue'
import TablePreheader from '@/shared/components/TablePreheader.vue'
import TableCell from '@/shared/components/TableCell.vue'
import EmptyState from '@/shared/components/EmptyState.vue'
import { calculateStateName } from '@/shared/utils/calculateStateName'
import { Column } from '../interfaces/column.model'

export default defineComponent({
  name: 'TableWrapper',
  props: {
    placeholder: String,
    addButtonText: String,
    isFilter: Boolean,
    hidePagination: Boolean,
    externalFilters: Object,
    stateName: String,
    stateToSetName: String,
    columns: Array,
    emptyStateTitle: String,
    emptyStateSubtitle: String,
    dataIncludeCount: Boolean
  },
  components: {
    Pagination,
    TablePreheader,
    TableCell,
    EmptyState
  },
  data: function () {
    return {
      searchText: '' as string,
      sortByColumn: {} as Column,
      sortDirection: 'ASC' as 'ASC' | 'DESC',
      rows: 10 as number,
      current: 1 as number
    }
  },
  computed: {
    dataCount: function () {
      return this.dataIncludeCount ? this.$store.state[this.stateName].count : this.$store.state[this.stateName + 'Count'] ? this.$store.state[this.stateName + 'Count'] : this.data ? this.data.length - 1 : 0
    },
    data: function (): Array<any> {
      return this.dataIncludeCount ? this.$store.state[this.stateName].data : this.$store.state[this.stateName]
    },
    allPages: function (): number {
      return Math.ceil(this.dataCount / this.rows)
    },
    resizedData: function (): any[] {
      return this.data && this.data.length > 0 ? this.$store.state[this.stateName + 'Count'] ? this.data : this.data.slice(0, this.rows) : []
    }
  },
  emits: ['rowsChanged', 'pageChanged', 'addButtonClicked'],
  methods: {
    getData (): void {
      if (this.stateName) {
        let basicFilters: any = {
          query: this.searchText,
          current: this.current,
          sortBy: Array.isArray(this.sortByColumn.properties) ? this.sortByColumn.properties.length > 0 ? this.sortByColumn.properties[0] : '' : this.sortByColumn.properties,
          order: this.sortByColumn ? this.sortDirection : ''
        }
        if (!this.hidePagination) {
          basicFilters = {
            ...basicFilters,
            limit: this.rows
          }
        }
        const filters = {
          ...basicFilters,
          ...this.externalFilters
        }

        localStorage.setItem('filters', JSON.stringify({ ...filters, sortByColumn: this.sortByColumn }))
        delete filters.sortByColumn
        this.$store.dispatch(calculateStateName('get', this.stateName), filters)
      }
    },
    setData (item: any): void {
      if (this.stateToSetName) {
        this.$store.dispatch(
          'set' + this.stateToSetName.charAt(0).toUpperCase() + this.stateToSetName.slice(1),
          item
        )
      }
    },
    resizeData ($event: number): void {
      this.rows = $event
      this.current = 1
      this.getData()
    },
    changePage ($event: -1 | 1): void {
      if ((this.current < this.allPages && $event > 0) || (this.current > 1 && $event < 0)) {
        this.current += $event
        this.getData()
      }
    },
    searchResults ($event: string): void {
      this.searchText = $event
      this.getData()
    },
    sortBy (column: Column): void {
      if (!column.noSort) {
        if (this.sortByColumn.name === column.name) this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC'
        else this.sortDirection = 'ASC'
        this.sortByColumn = column
        this.getData()
      }
    },
    toggled ($event: boolean, itemId: string, itemColumn: Column): void {
      const item = this.data.find((item: any) => item._id === itemId)
      item[itemColumn.properties as string] = $event ? itemColumn.trueValue : itemColumn.falseValue === undefined ? false : itemColumn.falseValue
      if (itemColumn.autoSave) this.setData(item)
    }
  },
  watch: {
    stateName: function () {
      localStorage.removeItem('filters')
      this.getData()
    },
    externalFilters: function (newFilters, oldFilters) {
      if (JSON.stringify(newFilters) !== JSON.stringify(oldFilters)) {
        this.getData()
      }
    }
  },
  beforeMount () {
    localStorage.removeItem('filters')
    this.getData()
  }
})
