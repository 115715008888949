import { Column } from '@/shared/interfaces/column.model'
import { formatDate } from '@/shared/utils/formatDate'
import { Component, onBeforeMount, reactive } from 'vue'
import { Opening } from '../interfaces/opening.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import Modal from '@/shared/components/Modal.vue'
import Toggle from '@/shared/components/Toggle.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import useVuelidate from '@vuelidate/core'
import { useStore } from 'vuex'
import { required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import { bookFields } from '../consts/bookFields'

const isFirstFunction = (openings: Opening[], itemId: string) => {
  let foundIndex = 0
  openings.find((opening: Opening, index: number) => {
    const found = opening._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: "date",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
        {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @blur="v$.editedItem.date.$touch"  :enable-time-picker="false"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @blur="v$.newItem.date.$touch" :enable-time-picker="false" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as Opening,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
            else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
            return true
          }
        },
        newItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
            else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "opening.checklist",
    properties: ["check0", "check1", "check2", "check3", "check4", "check5", "check6", "check7", "check8", "otherChecks"],
    customComponent: {
      template: `
        <button class="primary whitespace-nowrap" @click="open">{{ $t('opening.open') }}</button>
        <Modal v-if="showModal" title="Checklist" @closed="cancel()" :hideCancel="true">
          <div>
            <label>{{ $t('opening.check0') }}</label>
            <Toggle class="mb-5" :modelValue="item.check0" @update:modelValue="$event => item.check0 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check1') }}</label>
            <Toggle class="mb-5" :modelValue="item.check1" @update:modelValue="$event => item.check1 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check2') }}</label>
            <Toggle class="mb-5" :modelValue="item.check2" @update:modelValue="$event => item.check2 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check3') }}</label>
            <Toggle class="mb-5" :modelValue="item.check3" @update:modelValue="$event => item.check3 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check4') }}</label>
            <Toggle class="mb-5" :modelValue="item.check4" @update:modelValue="$event => item.check4 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check5') }}</label>
            <Toggle class="mb-5" :modelValue="item.check5" @update:modelValue="$event => item.check5 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check6') }}</label>
            <Toggle class="mb-5" :modelValue="item.check6" @update:modelValue="$event => item.check6 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check7') }}</label>
            <Toggle class="mb-5" :modelValue="item.check7" @update:modelValue="$event => item.check7 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <label>{{ $t('opening.check8') }}</label>
            <Toggle class="mb-5" :modelValue="item.check8" @update:modelValue="$event => item.check8 = $event" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            <div v-for="(check, index) of otherChecksAll" :key="index">
              <label>{{ $t(check.value) }}</label>
              <Toggle class="mb-5" :modelValue="!!item.otherChecks.find(checked => checked === check.name)" @update:modelValue="updateOtherChecks(check.name)" :disabled="item && (!editedItem || editedItem._id !== item._id) && !isFirst"></Toggle>
            </div>
          </div>
          <template #footer>
            <button class="primary" @click="confirm()">
              {{ $t('ok') }}
            </button>
          </template>
        </Modal>
      `,
      components: { Modal,Toggle },
      props: {
        item: {} as Opening,
      },
      data: function () {
        return {
          showModal: false,
          otherChecksAll: []
        }
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        }
      },
      methods: {
        async open () {
          await this.$store.dispatch('getBookFieldsByName', '06 MA Opening checks').then((fields: any[]) => this.otherChecksAll = fields)
          this.item.otherChecks = JSON.parse(JSON.stringify(this.otherChecks))
          this.showModal = true
        },
        close () {
          this.showModal = false
        },
        updateOtherChecks (checkName: string) {
          const index = this.item.otherChecks.indexOf(checkName)
          if (index >= 0) this.item.otherChecks.splice(index, 1)
          else this.item.otherChecks.push(checkName)
        },
        confirm () {
          if (this.isFirst && this.newItem) {
            this.check0 = this.item.check0
            this.check1 = this.item.check1
            this.check2 = this.item.check2
            this.check3 = this.item.check3
            this.check4 = this.item.check4
            this.check5 = this.item.check5
            this.check6 = this.item.check6
            this.check7 = this.item.check7
            this.check8 = this.item.check8
            this.otherChecks = this.item.otherChecks
          }
          this.close()
        },
        cancel () {
          this.item.check0 = this.check0
          this.item.check1 = this.check1
          this.item.check2 = this.check2
          this.item.check3 = this.check3
          this.item.check4 = this.check4
          this.item.check5 = this.check5
          this.item.check6 = this.check6
          this.item.check7 = this.check7
          this.item.check8 = this.check8
          this.item.otherChecks = JSON.parse(JSON.stringify(this.otherChecks))
          this.close()
        }
      },
    } as Component
  },
  {
    name: "opening.additional_checks",
    properties: "additionalChecks",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.additionalChecks }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.additionalChecks.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.additionalChecks.$error}" v-model="editedItem.additionalChecks" @blur="v$.editedItem.additionalChecks.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.additionalChecks.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.additionalChecks.$error}" v-model="newItem.additionalChecks" @blur="v$.newItem.additionalChecks.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Opening,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          additionalChecks: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { additionalChecks: vm.v$.editedItem.additionalChecks.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { additionalChecks: vm.v$.editedItem.additionalChecks.$invalid }
            else vm.isColumnsError.editedItem.additionalChecks = vm.v$.editedItem.additionalChecks.$invalid
            return true
          }
        },
        newItem: {
          additionalChecks: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { additionalChecks: vm.v$.newItem.additionalChecks.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { additionalChecks: vm.v$.newItem.additionalChecks.$invalid }
            else vm.isColumnsError.newItem.additionalChecks = vm.v$.newItem.additionalChecks.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "name",
    properties: "name",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.name }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.name.$error}" v-model="editedItem.name" @blur="v$.editedItem.name.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.name.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.name.$error}" v-model="newItem.name" @blur="v$.newItem.name.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Opening,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { name: vm.v$.editedItem.name.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { name: vm.v$.editedItem.name.$invalid }
            else vm.isColumnsError.editedItem.name = vm.v$.editedItem.name.$invalid
            return true
          }
        },
        newItem: {
          name: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { name: vm.v$.newItem.name.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { name: vm.v$.newItem.name.$invalid }
            else vm.isColumnsError.newItem.name = vm.v$.newItem.name.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "check0", "check1", "check2", "check3", "check4", "check5", "check6", "check7", "check8", "otherChecks", "name"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as Opening,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id, date: new Date(), check0: false, check1: false, check2: false, check3: false, check4: false, check5: false, check6: false, check7: false, check8: false, otherChecks: [], additionalChecks: '', name: '' })
        },
        cancel () {
          this.$store.dispatch('getData', {})
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];