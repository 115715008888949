import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tableWrapper" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TablePreheader = _resolveComponent("TablePreheader")!
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "table-caption"),
    (_ctx.resizedData.length > 0 )
      ? (_openBlock(), _createBlock(_component_TablePreheader, {
          key: 0,
          class: "mb-6",
          placeholder: _ctx.placeholder,
          addButtonText: _ctx.addButtonText,
          isFilter: _ctx.isFilter,
          onSearch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getData($event))),
          onAddButtonClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addButtonClicked')))
        }, null, 8, ["placeholder", "addButtonText", "isFilter"]))
      : _createCommentVNode("", true),
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createElementBlock("th", {
              key: column,
              class: _normalizeClass({ dontSort: column.noSort }),
              onClick: ($event: any) => (_ctx.sortBy(column)),
              nowrap: "true"
            }, _toDisplayString(_ctx.$t(column.name)), 11, _hoisted_2))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resizedData, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item._id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (itemColumn, index) => {
              return (_openBlock(), _createElementBlock("td", {
                class: _normalizeClass(itemColumn.class),
                key: index
              }, [
                _createVNode(_component_TableCell, {
                  item: item,
                  itemColumn: itemColumn,
                  onToggled: ($event: any) => (_ctx.toggled($event, item._id, itemColumn))
                }, null, 8, ["item", "itemColumn", "onToggled"])
              ], 2))
            }), 128))
          ]))
        }), 128)),
        (_ctx.resizedData.length === 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
              _createElementVNode("td", {
                colspan: _ctx.columns.length,
                class: "px-0"
              }, [
                _createVNode(_component_EmptyState, {
                  title: _ctx.emptyStateTitle,
                  subtitle: _ctx.emptyStateSubtitle
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                  ]),
                  _: 3
                }, 8, ["title", "subtitle"])
              ], 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (!_ctx.hidePagination)
      ? (_openBlock(), _createBlock(_component_Pagination, {
          key: 1,
          rows: _ctx.rows,
          current: _ctx.current,
          all: _ctx.allPages,
          onRowsChanged: _ctx.resizeData,
          onPageChanged: _ctx.changePage
        }, null, 8, ["rows", "current", "all", "onRowsChanged", "onPageChanged"]))
      : _createCommentVNode("", true)
  ]))
}