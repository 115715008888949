import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5108543b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tablePreheader flex max-w-full" }
const _hoisted_2 = {
  key: 0,
  class: "tablePreheader-search w-420 flex items-center max-w-full relative me-auto"
}
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_Filter = _resolveComponent("Filter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.placeholder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SearchIcon, { class: "text-gray absolute start-2.5" }),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
            class: _normalizeClass(_ctx.searchText && _ctx.searchText.length > 0 ? 'notEmpty' : ''),
            placeholder: _ctx.$t(_ctx.placeholder)
          }, null, 42, _hoisted_3), [
            [_vModelText, _ctx.searchText]
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["absolute end-2.5 cursor-pointer text-primary text-sm font-bold", _ctx.searchText && _ctx.searchText.length > 0 ? 'block' : 'hidden']),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchText = ''))
          }, _toDisplayString(_ctx.$t("clear")), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.addButtonText)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('addButtonClicked'))),
          class: "primary large"
        }, [
          _createVNode(_component_PlusIcon, { class: "me-3.5" }),
          _createTextVNode(" " + _toDisplayString(_ctx.addButtonText), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isFilter)
      ? (_openBlock(), _createBlock(_component_Filter, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}