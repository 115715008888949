import { Column } from '@/shared/interfaces/column.model'
import { formatDate } from '@/shared/utils/formatDate'
import { Component, reactive } from 'vue'
import { Delivery } from '../interfaces/delivery.model'
import { cancelTableData } from '../utils/cancelTableData'
import { saveTableData } from '../utils/saveTableData'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { numeric, required } from '@/customValidators'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'

const isFirstFunction = (deliverys: Delivery[], itemId: string) => {
  let foundIndex = 0
  deliverys.find((delivery: Delivery, index: number) => {
    const found = delivery._id === itemId
    if (found) foundIndex = index
    return found
  })
  return foundIndex === 0
}

/* eslint-disable */
export default [
  {
    name: "date",
    properties: "date",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ dateFormatted }}
        </div>
        <div v-else class="flex items-center">
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.editedItem.date.$error}" v-model="editedItem.date" @blur="v$.editedItem.date.$touch"  :enable-time-picker="false"/>
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.date.$errors">
            <VueDatePicker :auto-apply="true" v-bind:clearable="false" v-bind:class="{error: v$.newItem.date.$error}" v-model="newItem.date" @blur="v$.newItem.date.$touch" :enable-time-picker="false" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        VueDatePicker,
        FieldWrapper
      },
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        dateFormatted: function () {
          return this.item ? formatDate(this.date) : null
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { date: vm.v$.editedItem.date.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { date: vm.v$.editedItem.date.$invalid }
            else vm.isColumnsError.editedItem.date = vm.v$.editedItem.date.$invalid
            return true
          }
        },
        newItem: {
          date: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { date: vm.v$.newItem.date.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { date: vm.v$.newItem.date.$invalid }
            else vm.isColumnsError.newItem.date = vm.v$.newItem.date.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "delivery.supplier",
    properties: "supplier",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.supplier }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.supplier.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.supplier.$error}" v-model="editedItem.supplier" @blur="v$.editedItem.supplier.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.supplier.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.supplier.$error}" v-model="newItem.supplier" @blur="v$.newItem.supplier.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          supplier: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { supplier: vm.v$.editedItem.supplier.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { supplier: vm.v$.editedItem.supplier.$invalid }
            else vm.isColumnsError.editedItem.supplier = vm.v$.editedItem.supplier.$invalid
            return true
          }
        },
        newItem: {
          supplier: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { supplier: vm.v$.newItem.supplier.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { supplier: vm.v$.newItem.supplier.$invalid }
            else vm.isColumnsError.newItem.supplier = vm.v$.newItem.supplier.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "delivery.product",
    properties: "product",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.product }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.product.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.product.$error}" v-model="editedItem.product" @blur="v$.editedItem.product.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.product.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.product.$error}" v-model="newItem.product" @blur="v$.newItem.product.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          product: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { product: vm.v$.editedItem.product.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { product: vm.v$.editedItem.product.$invalid }
            else vm.isColumnsError.editedItem.product = vm.v$.editedItem.product.$invalid
            return true
          }
        },
        newItem: {
          product: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { product: vm.v$.newItem.product.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { product: vm.v$.newItem.product.$invalid }
            else vm.isColumnsError.newItem.product = vm.v$.newItem.product.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "temperature",
    properties: "temperature",
    customComponent: {
      template: `
      <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
        {{ item.temperature }}°C
      </div>
      <div v-else>
        <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.temperature.$errors">
          <div style="display: inline-block; position: relative;">
            <input type="number" v-bind:class="{error: v$.editedItem.temperature.$error}" v-model="editedItem.temperature" @blur="v$.editedItem.temperature.$touch" style="padding-right: 25px;"/>
            <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
          </div>
        </FieldWrapper>
        <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.temperature.$errors">
          <div style="display: inline-block; position: relative;">
            <input type="number" v-bind:class="{error: v$.newItem.temperature.$error}" v-model="newItem.temperature" @blur="v$.newItem.temperature.$touch" style="padding-right: 25px;"/>
            <label style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); pointer-events: none;">°C</label>
          </div>
        </FieldWrapper>
      </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          temperature: {
            required,
            numeric
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { temperature: vm.v$.editedItem.temperature.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { temperature: vm.v$.editedItem.temperature.$invalid }
            else vm.isColumnsError.editedItem.temperature = vm.v$.editedItem.temperature.$invalid
            return true
          }
        },
        newItem: {
          temperature: {
            required,
            numeric
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { temperature: vm.v$.newItem.temperature.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { temperature: vm.v$.newItem.temperature.$invalid }
            else vm.isColumnsError.newItem.temperature = vm.v$.newItem.temperature.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "delivery.is_accepted",
    properties: "isAccepted",
    trueValue: true,
    noSort: false,
    editionState: ["editedItem", "newItem"]
  },  
  {
    name: "comment",
    properties: "comment",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.comment }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.comment.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.comment.$error}" v-model="editedItem.comment" @blur="v$.editedItem.comment.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.comment.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.comment.$error}" v-model="newItem.comment" @blur="v$.newItem.comment.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          comment: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { comment: vm.v$.editedItem.comment.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { comment: vm.v$.editedItem.comment.$invalid }
            else vm.isColumnsError.editedItem.comment = vm.v$.editedItem.comment.$invalid
            return true
          }
        },
        newItem: {
          comment: {},
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { comment: vm.v$.newItem.comment.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { comment: vm.v$.newItem.comment.$invalid }
            else vm.isColumnsError.newItem.comment = vm.v$.newItem.comment.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "name",
    properties: "whoAdded",
    customComponent: {
      template: `
        <div v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst">
          {{ item.whoAdded }}
        </div>
        <div v-else>
          <FieldWrapper style="margin-bottom: 0" v-if="!isFirst && editedItem && editedItem._id" :errors="v$.editedItem.whoAdded.$errors">
            <input type="text" v-bind:class="{error: v$.editedItem.whoAdded.$error}" v-model="editedItem.whoAdded" @blur="v$.editedItem.whoAdded.$touch" />
          </FieldWrapper>
          <FieldWrapper style="margin-bottom: 0" v-if="isFirst && newItem" :errors="v$.newItem.whoAdded.$errors">
            <input type="text" v-bind:class="{error: v$.newItem.whoAdded.$error}" v-model="newItem.whoAdded" @blur="v$.newItem.whoAdded.$touch" />
          </FieldWrapper>
        </div>
      `,
      setup: () => {
        const store = useStore()
        store.commit('setVuelidateExternalResults', {})
        return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
      },
      components: {
        FieldWrapper
      },
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        newItem () {
          return this.$store ? this.$store.state.newItem : null
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      validations: {
        editedItem: {
          whoAdded: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { editedItem: { whoAdded: vm.v$.editedItem.whoAdded.$invalid } })
            else if (!vm.isColumnsError.editedItem) vm.isColumnsError.editedItem = { whoAdded: vm.v$.editedItem.whoAdded.$invalid }
            else vm.isColumnsError.editedItem.whoAdded = vm.v$.editedItem.whoAdded.$invalid
            return true
          }
        },
        newItem: {
          whoAdded: {
            required
          },
          changed: (value: string, vm: any) => {
            if (!vm.isColumnsError) vm.$store.commit('setIsColumnsError', { newItem: { whoAdded: vm.v$.newItem.whoAdded.$invalid } })
            else if (!vm.isColumnsError.newItem) vm.isColumnsError.newItem = { whoAdded: vm.v$.newItem.whoAdded.$invalid }
            else vm.isColumnsError.newItem.whoAdded = vm.v$.newItem.whoAdded.$invalid
            return true
          }
        }
      }
    } as Component
  },
  {
    name: "actions",
    properties: ["date", "supplier", "product", "temperature", "isAccepted", "comment", "temperature", "whoAdded"],
    noSort: true,
    customComponent: {
      template: `
        <div class="flex items-center gap-2">
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="primary" @click="editMode()">
            {{ $t('edit') }}
          </button>
          <button v-if="item && (!editedItem || editedItem._id !== item._id) && !isFirst" class="danger" @click="remove()">
            {{ $t('remove') }}
          </button>
          <button v-if="isEdited || isFirst" class="primary" @click="save()" :disabled="(isEdited && isInvalid(isColumnsError.editedItem)) || (isFirst && isInvalid(isColumnsError.newItem))">
            {{ $t('save') }}
          </button>
          <button v-if="isEdited && !isFirst" class="primary" @click="cancel()">
            {{ $t('cancel') }}
          </button>
        </div>
      `,
      props: {
        item: {} as Delivery,
      },
      computed: {
        isFirst () {
          return this.item && isFirstFunction(this.$store.state.data, this.item._id)
        },
        isEdited () {
          return this.item && this.editedItem && this.editedItem._id && this.editedItem._id === this.item._id
        },
        editedItem () {
          return this.$store ? this.$store.state.editedItem : null
        },
        isColumnsError () {
          return this.$store.state.isColumnsError
        }
      },
      methods: {
        isInvalid (errorsSet: any) {
          return errorsSet && Object.keys(errorsSet).find((key: string) => errorsSet[key])
        },
        remove () {
          this.$store.dispatch('removeData', this.item._id)
        },
        editMode () {
          this.$store.commit('setEditedItem', this.item)
        },
        save () {
          const itemToValidate = this.isFirst ? this.isColumnsError.newItem : this.isColumnsError.editedItem
          if (this.isInvalid(itemToValidate)) return;
          saveTableData(this, isFirstFunction(this.$store.state.data, this.item._id), { id: undefined, branchId: this.$store.state.activeBranch._id, date: new Date(), supplier: '', product: '', temperature: '', isAccepted: false, comment: '', whoAdded: '' })
        },
        cancel () {
          cancelTableData(this)
        }
      }
    } as Component,
  }
] as Column[];