export const saveTableData = (that: any, isFirst: boolean, newEmpty: any) => {
  if (that.item && isFirst) {
    Object.keys(newEmpty).forEach((key: string) => {
      if (that.$store.state.newItem[key] === undefined) that.$store.state.newItem[key] = newEmpty[key]
    })
    that.$store.dispatch('setData')
    that.$store.commit('setNewItem', newEmpty)
  } else {
    that.$store.dispatch('updateData')
    Object.keys(that.$store.state.editedItem).forEach((key: string) => {
      if (that.$store.state.editedItem[key]) that[key] = that.$store.state.editedItem[key]
    })
    that.$store.commit('setEditedItem', {})
    if (that.$store.state.editedItem && !!that.$store.state.editedItem.checklist) that.$store.commit('setOriginalChecklist', [])
  }
}
